@import './common/variables';
@import './common//mixins';
@import '../break-points.scss';
@import './common/icon';
@import './common/button';
@import '../scrollbar.scss';
//Sections
@import './sections/mainsection';
@import './sections/sidesection';

.community-page-container {
  width: $container-width;
  height: 100vh;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-bottom: 5.3rem;
  }
  .cursor{
      cursor: pointer;
  }
}
//Colors
$theme-blue: #7c7ef7;
$theme-orange: #f8c56c;
$theme-aqua: #52caed;
$theme-lightblue: #eeedff;
$theme-pink: #f3d3e8;
$theme-rose: #ff98bf;
$theme-grey:#F9F9F9;
$theme-green:#EAF9E6;
$theme-light-pink:#FDE4EE;
$theme-dark-grey:#EFEFEF;
$shadow-grey: #b0b0b0;
$dark-grey: #5f5f5f;
$grey-text:#636363;
$dark-grey-text:#5B5B5B;

//Width Properties
$side-bar-width: 8.5rem;
$container-width: calc(100vw - #{$side-bar-width});
$side-section-width: 25vw;
$main-section-width: calc(100vw - #{$side-section-width} - #{$side-bar-width});

//Font Size
$p-md-size: 12px;
$p-sm-size: 8px;
$md-size-resp: calc(8px + 0.5vw);

$icon-width-md: 2rem;
$icon-width-sm: 1rem;

//Borders
$test-border: 1px solid red;

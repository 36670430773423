//sections
@import './header';
@import './community';
@import './post';

.hw-main-section {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 2rem 2rem 0 2rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .hw-main-section {
    padding: 1rem 1rem 4rem 1rem !important;
  }
}

.side-section-container {
  width: 25vw;
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
  background-color: white;
  border-top-left-radius: 50px;
  .toggle-container {
    width: 100%;
    @include displayFlex(center, flex-end);
    margin: 1.5vw 0;
    img {
      cursor: pointer;
    }
  }
  .side-section-content {
    display: none;
    .title-container {
      width: 100%;
      @include displayFlex(center, space-between);
      margin: 1vw 0;
      p {
        font-weight: 600;
      }
      svg {
        color: #8c8c8c;
        cursor: pointer;
      }
    }
    .creato-stalk {
      width: 100%;
      height: 35vh;
      border-radius: 10px;
      background: linear-gradient(114.42deg, #f5ebf8 -11.99%, #f2ecfb 130.99%);
      margin: 1vw 0;
      @include displayFlex(center, space-between, column);
      .creato-stalk-body {
        width: 100%;
        height: 85%;
        position: relative;
        .creato-stalk-card {
          position: absolute;
          top: 50%;
          left: 50%;
          border: 1px solid white;
          border-radius: 10px;
          padding: 5% 2%;
          @include displayFlex(flex-start, space-between);
          -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
          box-shadow: 2px 2px 5px #c7c7c7;
          cursor: pointer;
          margin-bottom: 20px;
          &:hover {
            top: 47%;
            transition: 0.1s ease-in-out;
          }
          h1 {
            color: white;
            font-size: calc(8px + 0.5vw);
          }
          .dollar {
            width: 30px;
            height: 30px;
            border: 3px solid white;
            border-radius: 50%;
            @include displayFlex(center, center);
          }
        }
        .card-2,
        .card-3 {
          &:hover {
            top: 40%;
          }
        }
        @for $i from 1 through 3 {
          .card-#{$i} {
            width: calc(100% - (10% * #{$i}));
            height: 70%;
            transform: translate(-50%, calc(-30% - (10% * #{$i})));
            z-index: calc(4 - #{$i});
          }
        }
      }
      .creato-stalk-foot {
        width: 100%;
        padding: 0 5%;
        height: 15%;
        .creato-foot-content {
          @include displayFlex(center, space-between);
          color: #999999;
          font-size: calc(8px + 0.3vw);
        }
        .creato-foot-pointer {
          height: 25%;
          margin-top: 1%;
          background-color: white;
          border-radius: 10px;
          .pointer {
            width: 40%;
            height: 100%;
            background-color: #68cce9;
            border-radius: 10px;
          }
        }
      }
    }
    .creato-value {
      width: 100%;
      height: 30vh;
      border-radius: 10px;
      background-color: #eaf9e6;
      margin: 1vw 0;
      .creato-value-head {
        padding: 5%;
        width: 100%;
        height: 20%;
        @include displayFlex(center, flex-start);
        p:nth-child(1) {
          font-weight: 600;
        }
        span {
          @include displayFlex(center, flex-start);
          background-color: rgba(82, 202, 237, 0.23);
          margin-left: 5px;
          padding: 0 3px;
          border-radius: 10px;
          color: #3dc1e8;
          svg {
            width: 10px;
          }
          p {
            font-size: 10px;
          }
        }
      }
      .creato-value-chart {
        display: flex;
        width: 100%;
        height: 80%;
        .rechart-component {
          display: flex;
          // padding-left: -30px;
          align-items: flex-end;
          width: 80%;
          height: 100%;
        }
        .creato-value-buy-sell {
          display: flex;
          padding: 5px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .creato-value-buy,
          .creato-value-sell {
            display: flex;
            align-items: center;
            color: #a2a2ad;
            font-size: 15px;
            gap: 5px;
            .buy-indicator {
              width: 11px;
              height: 11px;
              background: #7c7ef7;
              border-radius: 50%;
            }
            .sell-indicator {
              width: 11px;
              height: 11px;
              background: #f8c56c;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .creato-balance {
      width: 100%;
      height: 23vh;
      border-radius: 10px;
      background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);
      margin: 1vw 0;
      @include displayFlex(center, space-between);
      .creato-balance-left {
        width: 20%;
        height: 100%;
        @include displayFlex(flex-start, center);
        .logo-icon {
          width: 3.5vw;
          height: 3.5vw;
          margin-top: 10%;
          background-color: white;
          border-radius: 50%;
          @include displayFlex(center, center);
          img {
            width: 50%;
          }
        }
      }
      .creato-balance-right {
        width: 80%;
        height: 100%;
        .balance-details-container {
          width: 100%;
          height: 70%;
          @include displayFlex(flex-start, space-around, column);
          /* .details-head{
            font-size: calc(5px + 0.3vw );
            font-weight: 600;
          } */
          .details-body {
            p:nth-child(1) {
              font-size: calc(5px + 0.3vw);
            }
            p:nth-child(2) {
              font-size: calc(5px + 0.5vw);
              font-weight: 600;
            }
          }
          .details-foot {
            @include displayFlex(center, flex-start);
            p:nth-child(1) {
              font-size: calc(5px + 0.3vw);
              color: #5b5b5b;
              @include displayFlex(center, flex-start);
              svg {
                width: calc(5px + 0.3vw);
              }
            }
            p:nth-child(2) {
              margin-left: 10px;
              font-size: calc(5px + 0.3vw);
            }
          }
        }
        .balance-btn-container {
          width: 100%;
          height: 30%;
          padding-right: 5px;
          @include displayFlex(center, space-between);
          button {
            padding: 1.5% 2%;
            border-radius: 10px;
            border: none;
            outline: none;
            color: white;
            font-size: calc(5px + 0.2vw);
            @include displayFlex(center, center);
            span {
              @include displayFlex(center, center);
              width: 14px;
              height: 14px;
              border: 1px solid white;
              border-radius: 50%;
              margin-right: 5px;
              font-size: calc(5px + 0.1vw);
              svg {
                width: calc(5px + 0.1vw);
              }
            }
          }
        }
      }
    }
    .creato-history {
      width: 100%;
      @include displayFlex(flex-start, space-between, column);
      .create-history-title {
        font-weight: 600;
      }
      .create-history-content {
        width: 100%;
        height: inherit;
        border-radius: 10px;
        background-color: #eeedff;
        margin: 1vw 0;
        .table-body {
          .name {
            p {
              font-size: calc(8px + 0.2vw);
              @include displayFlex(center, flex-start);
              white-space: nowrap;
              span {
                @include displayFlex(center, center);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                color: white;
                margin-right: 5px;
              }
            }
          }
          .price {
            color: #8c8c8c;
            font-size: calc(8px + 0.2vw);
          }
          .value {
            color: #6fcd96;
            font-size: calc(8px + 0.2vw);
          }
        }
        .view-all {
          width: 100%;
          padding: 3% 2%;
          p {
            font-size: calc(8px + 0.2vw);
            color: #7c7ef7;
            text-align: right;
          }
        }
      }
    }
  }
  .active {
    display: block;
  }
}
.side-section-close {
  width: 5vw;
  overflow: hidden;
}

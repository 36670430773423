// @import "./breakpoints.scss";

$breakpoints: (
  'xs': 0,
  'sm': 480px,
  'md': 720px,
  'lg': 960px,
  'xl': 1200px,
);

@mixin xs {
  @media (max-width: map-get($breakpoints,'xs')) {
    @content;
  }
}
@mixin sm {
  @media (max-width: map-get($breakpoints,'sm')) {
    @content;
  }
}
@mixin md {
  @media (max-width: map-get($breakpoints,'md')) {
    @content;
  }
}
@mixin lg {
  @media (max-width: map-get($breakpoints,'lg')) {
    @content;
  }
}
@mixin xl {
  @media (max-width: map-get($breakpoints,'xl')) {
    @content;
  }
}

.modalBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  // border-radius: 35px;
  overflow: hidden;
  background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);
  //   background-color: red;
}

.modalContainer {
  position: relative;
  background-color: #fff;
  min-width: 320px;
  height: 90%;
  border-radius: 27px;
  // margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;

  .orb-canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // @import './blur-bg.scss';
    .landingpage-container {
      width: 100%;
      height: 100%;
      .createverse-title {
        color: #7c7ef7;
        margin: 2% 5%;
      }
      .welcome-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .welcome-component {
          width: 35%;
          height: 50%;
          min-width: 320px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          //border: 1px solid red;
          .welcome-head {
            height: 20%;
            h1 {
              color: white;
              font-size: 3.5rem;
              animation-duration: 1.4s;
            }
          }
          .welcome-body {
            width: 100%;
            height: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            animation-delay: 0.4s;
            .welcome-btn {
              width: 80%;
              border: none;
              border-radius: 15px;
              // padding: 2%;
              // margin: 1%;
              padding: 12px;
              margin: 8px;
              animation-delay: 0.4s;
              cursor: pointer;
            }
            .register-btn {
              background-color: #7c7ef7;
              color: white;
            }
            .guest-btn {
              color: #7c7ef7;
              border: 1px solid #7c7ef7;
              font-weight: 600;
            }
          }
          .welcome-foot {
            height: 20%;
            p {
              color: #969696;
              text-align: center;
              span {
                a {
                  text-decoration: none;
                  color: #7c7ef7;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  h1 {
    padding-top: 20px;
  }

  h2 {
    font-weight: 500;
  }

  h2,
  p {
    color: #8e8e8e;
    span {
      color: #7c7ef7;
      cursor: pointer;
    }
  }
  // @include xl {
  //   width: 60%;
  // }
  // @include lg {
  //   width: 65%;
  // }
  // @include md {
  //   width: 75%;
  // }
}

.videoContainer {
  width: 90%;
  height: 50%;
  background: linear-gradient(105.29deg, #7c7ef7 -13.07%, #f3d3e8 52.07%, #fe98bf 119.71%);
  border-radius: 23px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 100%;
    // width: 100%;
  }
}

.registerButton,
.guestButton {
  cursor: pointer;
  width: 50%;
  height: 2.5rem;
  border-radius: 50px;
  border: none;
  background: #7c7ef7;
  color: #fff;
  font-weight: 600;
  border: 1.5px solid #7c7ef7;
}

.guestButton {
  color: #7c7ef7;
  background: #fff;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  height: 25px;
  width: 25px;
  top: 30px;
  right: 30px;
  background: #ffffff;
  color: #7a7a7a;
  border: 1px solid #7a7a7a;
  border-radius: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  z-index: 2;
}

@import '../../break-points.scss';

.confirmation-container {
  width: 100%;
  height: 100%;
  .confirmation-head {
    width: 100%;
    height: 5%;
    h1 {
      font-size: calc(10px + 1.2vw);
      @include sm{
        font-size: calc(16px + 1.2vw);
        text-align: center;
      }
    }
  }
  .confirmation-body {
    width: 100%;
    height: 95%;
    @include displayFlex(center, space-around, column);
    padding: 2%;
    @include sm{
      @include displayFlex(center,flex-start, column);
      padding: 0;
    }
    .coin-details {
      width: 100%;
      height: 35%;
      background-color: #f9f9f9;
      border-radius: 10px;
      @include sm{
        height: 25%;
      }
      .coin-details-top {
        width: 100%;
        height: 50%;
        @include displayFlex(center, space-around, column);
        img{
          @include sm{
            width: 35px;
          }
        }
        p {
          text-align: center;
          @include sm{
            font-size: calc(10px + 0.5vw);
          }
          @include xs{
            font-size: calc(0.5vw);
          }
          span {
            color: #6467fe;
          }
        }
      }
      .coin-details-bottom {
        width: 100%;
        height: 50%;
        border-top: 1px solid #ebebeb;
        padding: 0 2%;
        @include displayFlex(center, space-between);
        .coin-bottom-left {
          height: 100%;
          flex: 2;
          @include displayFlex(center, flex-start);
          .coin-btm-icon {
            height: 100%;
            @include displayFlex(center, center);
            img {
              height: 60%;
              @include sm{
                width: 35px;
                height: auto;
              }
            }
          }
          .coin-btm-details {
            margin-left: 10px;
            p {
              font-size: calc(8px + 0.3vw);
            }
            h1 {
              font-size: calc(10px + 0.5vw);
            }
          }
        }
        .coin-bottom-right {
          flex: 1;
          @include displayFlex(center, center);
          button {
            padding: 4% 8%;
            border-radius: 5px;
            border: none;
            font-size: 600;
            color: #7c7ef7;
            cursor: pointer;
          }
        }
      }
    }
    .token-details {
      width: 100%;
      height: 37%;
      background-color: #f9f9f9;
      border-radius: 10px;
      @include sm{
        margin-top: 5%;
        height: 35%;
      }
      .token-details-title {
        text-align: center;
        margin-top: 2%;
        @include sm{
          font-size: calc(10px + 0.5vw);
        }
      }
      .token-details-content {
        width: 100%;
        height: 100%;
        padding: 2%;
        ul {
          width: 100%;
          height: 90%;
          list-style-type: none;
          margin-left: 8%;
          @include displayFlex(flex-start, space-around, column);
          li {
            margin: 2% 0;
            width: 50%;
            @include displayFlex(center, space-between);
            @include sm{
              width: 80%;
              @include displayFlex(center, flex-start);
            }
            .dot-circ {
              margin-right: 5px;
            }
            .token-details-data{
                p{
                    font-size:0.8rem;
                    @include sm{
                      font-size: calc(10px + 0.5vw);
                    }
                }
                p:nth-child(1){
                    color: #7C7EF7;
                    font-weight: 600;
                }
                p:nth-child(2){
                    color:#9C9C9C;
                }
            }
          }
        }
      }
    }
    .button-container {
      width: 100%;
      height: 20%;
      @include displayFlex(center,center,column);
      @include sm{
        margin-top: 25%;
      }
      .continue-btn{
          width: 80%;
          padding: 2%;
          margin-bottom: 2%;
          border: none;
          color:white;
          background-color: #7C7EF7;
          border-radius: 15px;
          font-size: calc(10px + 0.5vw);
          font-weight: 600;
          cursor: pointer;
          @include sm{
            padding: 4%;
          }
      }
      a{
          margin: 5%;
          color: #7C7EF7;
          text-decoration: none;
          @include sm{
            width: 80%;
            padding: 4%;
            display: block;
            background-color: white;
            font-size: calc(10px + 0.5vw);
            border-radius: 15px;
            text-align: center;
          }
      }
    }
  }
}

@import './comments';
.post-content-container {
  width: 100%;
  .post {
    position: relative;
    border-bottom: 1.5px solid #dadada;
    margin-top: 2rem;
    padding: 0 0 1rem 1rem;

    &.has-no-border {
      border: none;
    }
    .head {
      width: 40%;
      margin-bottom: 1rem;
      @include displayFlex(center, space-between);
      h1 {
        white-space: nowrap;
        font-size: calc(8px + 0.5vw);
      }
      .likes-container {
        @include displayFlex(center, space-between);
        img {
          margin: 0 5%;
        }
        .likes-per-container {
          width: 100px;
          height: 5px;
          background-color: #d9d9d9;
          border-radius: 5px;
          .liked-percentage {
            width: 50%;
            height: 100%;
            border-radius: 5px;
            background-color: #52caed;
          }
        }
      }
    }
    .desc {
      #post-content {
        margin-bottom: 1rem;
      }
      p,
      #post-content {
        color: #7a7a7a;
      }

      .comments-foot {
        @include displayFlex(center, space-between);
        .foot-left {
          @include displayFlex(center, space-between);
          margin-bottom: 0.75rem;
          p {
            margin-right: 2rem;
            color: #9c9c9c;
            cursor: pointer;
            @include sm{
              font-size: 12px;
              margin-right: 0.5rem;
            }
          }
        }
        .foot-right {
          margin-bottom: 0.75rem;
          p {
            color: #9c9c9c;
            cursor: pointer;
            @include sm{
              font-size: 12px;
            }
          }
          svg {
            color: #9c9c9c;
            cursor: pointer;
          }
        }
      }
      .reply-input-container {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        #helper-text {
          text-align: end;
          color: #7a7a7a;
          width: 70%;
          font-size: 0.75rem;
        }
      }
    }
    .post-upvotes {
      position: absolute;
      top: 0;
      left: -4%;
      transform: translate(0px, 0px);
      svg{
        @include sm{
          width: 0.8rem;
          height: 0.8rem;
        }
      }
      p {
        color: #52caed;
      }
    }
  }
}
.comments {
  @include displayFlex(flex-end, center, column);
  margin: 2% 0;
}

@import './scrollbar.scss';
@import './break-points.scss';

.home-right-side-chat-container {
  margin-top: 20px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #eeedff;
  border-radius: 10px;
  @include xl {
    display: none;
  }

  .chat-heading {
    width: 100%;
    // height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    .chat-icons {
      display: flex;
      align-items: center;
      gap: 0 8px;
      cursor: pointer;
    }
  }
  .chat-search-input {
    width: 100%;
    padding: 0 1rem;

    .chat-input-with-icon {
      height: 31px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-radius: 14px;
      padding: 5px;

      input {
        width: 93%;
        height: 90%;
        outline: none;
        border: none;
        border-radius: 14px;
        padding-left: 10px;
      }
    }
  }
  .chat-content {
    margin-top: 20px;
    width: 100%;
    height: calc(100vh - 14.5rem);
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;

    @extend .scroll-bar-4;

    .chat-border {
      width: 100%;
      height: 48px;
      display: flex;

      .chat-image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;

        img {
          height: 100%;
        }
      }

      .chat-message {
        margin-left: 10px;
        line-height: 1.5;

        h3 {
          font-size: 16px;
          cursor: pointer;
        }
        p {
          font-size: 13px;
        }
      }
      .chat-seen {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: 12px;
      }
    }
  }
}

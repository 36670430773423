@import './colors.scss';
@import './scrollbar.scss';
@import './break-points.scss';

.upload-image-modal {
  min-width: 25rem;
  min-height: 6.25rem;
  max-height: 95%;
  height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.5rem;
  background-color: white;
  z-index: 10 !important;
  width: 55% !important;
  display: flex;
  padding-bottom: 1rem;

  @include xl {
    width: 70% !important;
  }

  @include sm {
    width: 50% !important;
  }

  .header {
    display: flex;
    height: 3rem;
    width: 100%;
    justify-content: flex-end;
    align-items: baseline;
    padding: 0.5rem;
  }
  .body {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 3rem);
    padding: 0rem 2.5rem 2rem 3rem;
    overflow: auto;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    @include xl {
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }

    .image-upload {
      display: flex;
      flex-direction: column;
      padding: 4.5rem;
      box-shadow: 0px 2px 55px rgb(45 33 124 / 28%);
      border-radius: 50%;
      align-items: center;
      cursor: pointer;
      position: relative;

      .circular-progress {
        width: 13.375rem !important;
        height: 13.375rem !important;
        z-index: 10;
        position: absolute;
        top: 0.3125rem;
        // background: -webkit-linear-gradient(#eee, #333);
        // -webkit-background-clip: icon;
        // -webkit-text-fill-color: transparent;
      }
    }

    .image-details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50%;
      padding-top: 1rem;

      @include xl {
        width: 100%;
      }

      .chip-stack {
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        .skill-arrow {
          display: none;
        }

        &:hover {
          .skill-arrow {
            display: block;
          }
        }

        .chip {
          border-radius: 0.5rem;
          color: white;
          padding: 0.2rem 1rem;
          font-weight: bold;
          height: unset !important;
          cursor: pointer;
        }
      }

      .tag-container {
        display: flex;
        min-height: 5rem;
        max-height: 7rem;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        border: 1px solid #c4c4c4;
        gap: 0.5rem;
        padding: 0.5rem;
        flex-wrap: wrap;
        border-radius: 1.25rem;

        .chip {
          border-radius: 0.5rem;
          color: white;
          padding: 0.2rem;
          font-weight: bold;
          cursor: pointer;
        }

        button {
          padding: 0.125rem;
          svg {
            font-size: 1rem;
            color: black;
          }
        }
      }

      .switch {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0.5rem;
      }

      .react-switch-checkbox {
        height: 0;
        width: 0;
        visibility: hidden;
      }

      .react-switch-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 40px;
        height: 20px;
        background: #dbdbdb;
        border-radius: 100px;
        position: relative;
        transition: background-color 0.2s;
        @include lg {
          width: 35px;
          height: 17px;
        }
      }

      // .react-switch-label:checked{}

      .react-switch-label .react-switch-button {
        content: '';
        position: absolute;
        top: 0.125rem;
        left: 0.125rem;
        width: 1.1875rem;
        height: 16px;
        border-radius: 2.8125rem;
        transition: 0.2s;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
        @include lg {
          width: 13.5px;
          height: 13.5px;
        }
      }

      .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }

      .react-switch-checkbox:checked ~ .react-switch-label {
        background-color: #7c7ef7;
      }

      .react-switch-label:active .react-switch-button {
        width: 1.625rem;

        @include lg {
          width: 1.375rem;
        }
      }
    }
  }
}

.share-post-modal {
  min-width: 25rem;
  height: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.5rem;
  background-color: white;
  z-index: 10 !important;
  width: 40% !important;
  display: flex;
  flex-direction: column !important;

  .header {
    height: 1rem;
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 1rem;

    .icon-btn {
      color: var(--theme-font-color);
      position: absolute;
      top: 1rem;

      svg {
        &:hover {
          background-color: var(--theme-hover);
        }
      }
    }
  }

  .body {
    height: calc(100% - 3rem);
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
    overflow: auto;
    @extend .scroll-bar-5;
  }
}

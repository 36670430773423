.inputContainer {
    padding: 2rem;
    background-color: aliceblue;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    height: 20vh;
    @include md{
      width: 100%;
      padding: 1rem;
      height: fit-content;
    }
    @include sm{
      margin: 2% 0;
    }
  }
.scheduleInputContainer{
  display: flex;
  align-items: center;
  @include md{
    justify-content: center;
  }
  svg{
    font-size: 1.5rem;
    @include md{
      font-size: $p-md-size + 6;
    }
  }
}
  .scheduleContainer {
    @include displayFlex(space-between);
    @include md{
      height: auto;
      flex-direction: column;
    }
  }
  
  .scheduleOptionContainer{
    flex-grow: 1;
    display: flex;
    align-items: center;
    @include md{
      margin-bottom: 1%;
      justify-content: center;
      width: 100%;
    }
  }
  .postDropDownContainer,.scheduleDateContainer{
    margin-right: 1%;
    padding: 5px;
    width: 200px;
    height: 35px;
    font-size: 10px;
    background-color: white;
    box-shadow: 0 2px 2px #E4E4E4;
    display: flex;
    align-items: center;
    @include md{
      width:180px;
      font-size: $p-sm-size;
    }
  }
  .postDropDownContainer{
    justify-content: space-between;
  }
  .postDropDownIcon,.scheduleDateIcon{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    @include displayFlex(center);
    font-size: 1rem;
    color: white;
  }
  .postDropDownIcon{
    @include bgColor($theme-blue);
  }
  .scheduleDateIcon{
    @include bgColor($theme-orange);
  }
  
  .postDropDownContent,.scheduleDateContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 2%;
    flex-grow: 1;
  }
  .postDropDownContent p:nth-child(2),
  .scheduleDateContent p:nth-child(2){
      font-weight: 700;
  }
  .dropDownArrowIcon{
    font-size: 1rem;
    color:var(--theme-aqua);
  }
  .scheduleButtonContainer button{
    @include scheduleButton;
  }
  
  .textArea {
    padding: 1rem;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    @include md{
      width: 80%;
    }
  }
  
.hw-header-community {
  .hw-header-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3%;
    .icon-btn {
      color: var(--theme-font-color);
      svg {
        &:hover {
          background-color: var(--theme-hover);
        }
      }
    }
    h1 {
      font-size: 3rem;
      @media only screen and (max-width: 1500px) {
        display: flex;
        align-items: center;
        font-size: calc(1.3rem + 0.5vw);
      }
      @include lg {
        font-size: calc(1rem + 0.5vw);
      }
      @include sm{
        font-size: 1.5rem;
        flex-wrap: wrap;
      }
      img {
        height: 2.5rem;
        @media only screen and (max-width: 1500px) {
          height: 2rem;
        }
        @include sm{
          height: 1.5rem;
        }
      }
    }
  }
  .banner-container {
    position: relative;
    height: 158px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    background-color: white;
    @include lg {
      height: 128px;
    }
    img {
      width: 100%;
    }
     
  }
  .head-widgets-community {
    width: 95%;
    margin: 1% 0;
    @include displayFlex(center, flex-end);
    .bell-icon {
      @include icon(1.3rem);
    }
    .join-button-container {
      button {
        @include btn;
        background-color: $theme-blue;
        color: white;
        margin-left: 10px;
        border-radius: 5px;
        font-size: 1rem;
        @include sm{
          //height:10rem;
        }
      }
    }
  }
}

.social-links-container {
  position: relative;
  .social-links-body {
    margin: $overall-margin;
    @include displayFlex(center,center,column);
    .link-dropdown-container {
      width: 100%;
      @include displayFlex(center, space-between);
      .link-widget-left {
        position: relative;
        width: 40%;
        @include displayFlex(center, space-between);
        cursor: pointer;
        padding: 1% 4%;
        @include sm{
          width: 50%;
        }
        .link-head-icon {
          display: flex;
          align-items: center;
          img {
            width: 25px;
          }
        }
        .link-head-title {
          color: $theme-blue-violet;
        }
        .drop-down-list-container {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          transform: translate(0, 100%);
          ul {
            list-style-type: none;
            li {
              display: flex;
              align-items: center;
              margin: 5% 0;
              padding: 1% 0;
              &:hover {
                background-color: white;
              }
              img {
                width: 30px;
                height: 30px;
                margin: 0 5%;
              }
              p {
                margin-left: 5%;
              }
            }
          }
        }
      }
      .link-widget-right {
        position: relative;
        svg {
          color: #9c9c9c;
          cursor: pointer;
        }
        p {
          position: absolute;
          bottom: -100%;
          left: -50%;
          transform: translate(-73%, 10px);
          width: 150px;
          padding: 10px;
          background: #f9f9f9;
          box-shadow: 0px 0px 19px 1px rgb(0 0 0 / 9%);
          border-radius: 5px;
          color: #f6556f;
          cursor: pointer;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
    .social-link-input-container {
      width: 100%;
      @include displayFlex(flex-start, center, column);
      background: #f9f9f9;
      border: 1.5px solid #f2f2f2;
      border-radius: 18px;
      padding: 2% 4%;
      margin: 5%;
      label {
        font-size: 10px;
        color: #9c9c9c;
        margin-bottom: 1%;
      }
      input {
        border: none;
        outline: none;
        font-size: 12px;
        font-weight: 600;
        background-color: transparent;
        &::placeholder {
          color: $theme-blue-violet;
        }
      }
    }
  }
}

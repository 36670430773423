.chart-container {
  background-color: white;
  height: 45vh;
  border-radius: 10px;
  .head {
    @include displayFlex(center, space-between);
    padding: 4% 2%;
    height: 15%;
    .head-data {
      @include displayFlex(flex-start, center, column);
      .total-balance {
        @include setSize(0.5vw);
        color: #494949;
        font-weight: 400;
      }
      .price-detail {
        margin-top: 2%;
        @include displayFlex(center, space-between);
        h1 {
          @include setSize(0.5vw);
        }
        p {
          @include displayFlex(center, center);
          margin-left: 10px;
          color: #3dc1e8;
          padding: 2% 3%;
          white-space: nowrap;
          @include setSize(0.2vw);
          background-color: rgba(82, 202, 237, 0.23);
          border-radius: 8px;
          svg {
            @include setSize(0.2vw);
          }
        }
      }
    }
    .report-btn {
      button {
        padding: 5px 10px;
        background-color: transparent;
        border: 1px solid #9e9e9e;
        color: #9e9e9e;
        white-space: nowrap;
        border-radius: 50px;
        cursor: pointer;
      }
    }
  }
  .body {
    width: 100%;
    height: 85%;
    .rechart-component {
      padding: 0 25px 10px 25px;
      width: 100%;
      height: 100%;

      @include md {
        padding: 0 10px;
      }
    }
  }
}

@import './confirmation';
@import './createContainer';
@import '../../break-points.scss';
.community-layout {
  width: 500px;
  aspect-ratio: 1/1.4;
  border-radius: 25px;
  background-color: white;
  position: relative;
  @include displayFlex(center, center);
  @include sm {
    background-color: transparent;
    height: 100%;
    aspect-ratio: 1/2.2;
  }
  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-20%, 15%);
    cursor: pointer;
    margin: 2%;
    @include sm {
      transform: translate(10%, -20%);
    }
    img {
      width: 25px;
      @include sm {
        opacity: 0.4;
      }
    }
  }
  .community-content {
    width: 100%;
    height: 100%;
    padding: 5%;
  }
}

.blur-bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .blur-com-container {
      position: relative;
      width: 80%;
      height: 80%;
      animation: animeBg 25s ease-in-out infinite;
      .bg-color {
        position: absolute;
        width: 200px;
        height: 200px;
        filter: blur(100px);
        &.bg-color-1 {
          background: rgba(235, 188, 255, 1);
          top: 50%;
          left: 50%;
        }
        &.bg-color-2 {
          background: rgba(124, 126, 247, 0.2);
          top: 50%;
          right: 50%;
        }
        &.bg-color-3 {
          background: rgba(255, 242, 192, 1);
          bottom: 50%;
          left: 50%;
        }
        &.bg-color-4 {
          background: rgba(122, 233, 255, 1);
          bottom: 50%;
          right: 50%;
        }
        @keyframes animeBg {
          0% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(80deg);
          }
          50% {
            transform: rotate(160deg);
          }
          75% {
            transform: rotate(240deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        // &::after,
        // &::before {
        //   content: '';
        //   position: absolute;
        //   top: -3px;
        //   left: -3px;
        //   width: calc(100% + 6px);
        //   height: calc(100% + 6px);
        //   background: linear-gradient(
        //     90deg,
        //     rgba(124, 126, 247, 1) 0%,
        //     rgba(235, 188, 255, 1) 35%,
        //     rgba(255, 242, 192, 1) 60%,
        //     rgba(122, 233, 255, 1) 100%
        //   );
        // }
      }
    }
  }
  
.community-menu-container {
  width: 100%;
  aspect-ratio: 1/0.3;
  padding: 5vh;
  border-radius: 1.5rem;
  position: relative;
  background-color: white;
  @include displayFlex(center, space-between);
  @media only screen and (max-width: 1500px) {
    padding: 3vh;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  @include sm {
    aspect-ratio: 1/0.5;
    padding: 2vh;
  }
  .user-icon {
    @include user-icon(8rem);
    background-color: white;
    overflow: hidden;
    position: absolute;
    top: -40%;
    left: 5%;
    transform: translate(-5%, 35%);
    @media only screen and (max-width: 1500px) {
      top: -60%;
      @include user-icon(8vw);
    }
    @include lg {
      @include user-icon(12vw);
    }
    @media only screen and (max-width: 600px) {
      top: -25%;
    }
    @include sm{
      @include user-icon(60px);
      top:-23%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .community-menu {
    height: 100%;
  }
  .community-center {
    flex: 1;
    @media only screen and (max-width: 600px) {
      width: 90%;
    }
  }
  .community-left,
  .community-right {
    flex: 1.5;
    @media only screen and (max-width: 600px) {
      width: 90%;
      gap: 1rem;
    }
    @include sm{
      width: 100%;
    }
  }
  .community-left {
    @include displayFlex(flex-start, space-around, column);
    .community-left-head {
      h1 {
        @include setSize(1.3rem, 2px);
        @include sm{
          margin-top: 0.5rem;
        }
      }
      p {
        @include setSize(0.8rem, 2px);
        color: $grey-text;
        font-weight: 500;
        margin-left: 5px;
      }
    }
    .community-left-body {
      p {
        @include setSize(1.2rem, 2px);
        color: $grey-text;
        @media only screen and (max-width: 1300px) {
          font-size: calc(0.5vw + 8px);
        }
        @include sm{
          font-size: calc(0.5vw + 10px);
        }
        span {
          font-weight: 600;
          color: black;
        }
      }
    }
    .community-left-foot {
      @include displayFlex(center, space-between);
      width: 100%;
      h1 {
        font-size: 1rem;
        @include lg {
          font-size: calc(10px + 0.5vw);
        }
      }
      .tags-container {
        @include displayFlex(center, space-between);
        margin-left: 10px;
        flex-grow: 1;
        @include sm{
          gap:0.3rem;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        button {
          width: 6rem;
          color: white;
          @include btn;
          @media only screen and (max-width: 1300px) {
            width: 4rem;
            font-size: 10px;
            padding: 0.5rem;
          }
          @include lg {
            width: 3rem;
            font-size: 10px;
            padding: 0.2rem !important;
          }
          @include sm{
            width: 5rem;
          }
        }
        button:nth-child(1) {
          background-color: $theme-blue;
        }
        button:nth-child(2) {
          background-color: #ffc35a;
        }
        button:nth-child(3) {
          border: 1px dashed $shadow-grey;
          background-color: transparent;
          color: $shadow-grey;
        }
      }
    }
  }
  .community-center {
    @include displayFlex(center, flex-start, column);
    h1 {
      color: $theme-blue;
    }
    h1:nth-child(1) {
      @include setSize(4rem, 2px);
      @media only screen and (max-width: 1300px) {
        font-size: calc(2rem + 0.5vw);
      }
    }
    h1:nth-child(2) {
      font-size: calc(8px + 1vw);
      @include displayFlex(center, space-between);
      @media only screen and (max-width: 1300px) {
        font-size: calc(8px + 0.5vw);
      }
      span {
        @include displayFlex(center, center);
        margin-left: 5px;
        font-size: 10px;
        color: white;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: $shadow-grey;
      }
    }
  }
  .community-right {
    @include displayFlex(center, space-between, column);
    .buy-sell-container {
      width: 90%;
      flex: 1;
      @include displayFlex(flex-start, space-around, column);
      @media only screen and (max-width: 600px) {
        @include displayFlex(center, space-around);
      }
      @include sm{
        width: 100%;
      }
      .buy-container {
        @include displayFlex(center, flex-start);
        button {
          @include btn;
          background-color: $theme-blue;
          color: white;
          font-size: 1rem;
          padding: 0.5rem 2rem;
          margin-right: 10px;
          border-radius: 6px;
          @media only screen and (max-width: 1300px) {
            padding: 0.5rem 1rem;
          }
        }
        p {
          @include setSize(0.8rem, 2px);
          color: $grey-text;
        }
      }
      .sell-container {
        @include displayFlex(center, flex-start);
        button {
          @include btn;
          background-color: $theme-aqua;
          color: white;
          font-size: 1rem;
          padding: 0.5rem 2rem;
          margin-right: 10px;
          border-radius: 6px;
          @media only screen and (max-width: 1300px) {
            padding: 0.5rem 1rem;
          }
        }
        .sell-desc {
          p {
            margin: 5px 0;
            color: $grey-text;
            @include setSize(0.8rem, 2px);
          }
        }
      }
    }
    .member-icons-container {
      flex: 0.8;
      width: 90%;
      display: flex;
      align-items: center;
      @include lg {
        width: 100%;
      }
      .member-icons {
        width: 11rem;
        height: 3rem;
        position: relative;
        @media only screen and (max-width: 1500px) {
          height: 2rem;
        }
        img {
          width: 3rem;
          height: 3rem;
          position: absolute;
          top: 0;
          border-radius: 50%;
          @media only screen and (max-width: 1500px) {
            width: 2rem;
            height: 2rem;
          }
        }
        @for $i from 1 through 5 {
          img:nth-child(#{$i}) {
            left: calc(2rem * #{$i - 1});
          }
        }
      }
      p {
        display: inline;
        color: #5b5b5b;
        @include setSize(0.7rem, 2px);
        @include lg {
          font-size: 1.2vw;
        }
        @include sm{
          font-size: calc(10px + 0.5vw);
        }
      }
    }
  }
}

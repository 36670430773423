.thread-container {
  width: 100%;
  padding: 2% 0;
  @include displayFlex(flex-start, space-between);

  &.has-border {
    border-bottom: 1.5px solid #dadada;
  }
  .comments-left {
    @include displayFlex(center, space-between, column);
    margin: 0 1%;
    img {
      width: 20px;
      margin-right: 5px;
    }
    .upvotes-container {
      width: inherit;
      @include displayFlex(center, space-between, column);
      svg {
        width: 10px;
      }
      .up-arrow {
        color: #fe98bf;
      }
      .down-arrow {
        color: #bdbdbd;
      }
      p {
        font-size: 12px;
        color: #fe98bf;
      }
    }
  }
  .comments-right {
    flex-grow: 1;
    .comments-head {
      @include displayFlex(center, space-between);
      margin-bottom: 0.75rem;
      .profile-details {
        @include displayFlex(center, space-between);
        p {
          color: $theme-blue;
        }
      }
      .time {
        p {
          font-size: calc(8px + 0.4vw);
          color: #7a7a7a;
          font-style: italic;
        }
      }
    }
    .comments-body {
      margin-bottom: 0.75rem;

      p {
        margin: 1% 0;
        color: #7a7a7a;
      }
    }
    .comments-foot {
      @include displayFlex(center, space-between);
      .foot-left {
        @include displayFlex(center, space-between);
        margin-bottom: 0.75rem;

        p {
          margin-right: 2rem;
          color: #9c9c9c;
          cursor: not-allowed;
          @include sm {
            margin-right: 1rem;
            font-size: 12px;
          }
        }
      }
      .foot-right {
        margin-bottom: 0.75rem;

        p {
          color: #9c9c9c;
          cursor: not-allowed;
          @include sm {
            font-size: 12px;
          }
        }
        svg {
          color: #9c9c9c;
          cursor: pointer;
        }
      }
    }
    .reply-input-container {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      #helper-text {
        text-align: end;
        color: #7a7a7a;
        width: 70%;
        font-size: 0.75rem;
      }
    }
  }
}

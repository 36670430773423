@import "./colors.scss";

.scroll-bar {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    overflow: visible !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      cursor: hand;
    }
  }
}

.scroll-bar-1 {
  @extend .scroll-bar;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-lighter-grey;

    &:hover {
      background-color: $color-lighter-grey;
    }
  }
}

.scroll-bar-2 {
  @extend .scroll-bar;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-light-grey;

    &:hover {
      background-color: $color-light-grey;
    }
  }
}

.scroll-bar-3 {
  @extend .scroll-bar;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-light-grey;

    &:hover {
      background-color: $color-light-grey;
    }
  }
}

.scroll-bar-4 {
  @extend .scroll-bar;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray;

    &:hover {
      background-color: $color-gray;
    }
  }
}

.scroll-bar-5 {
  @extend .scroll-bar;

  &::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray;

    &:hover {
      background-color: $color-gray;
    }
  }
}

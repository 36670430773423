@import './common/variables';
@import '../break-points.scss';
@import './common/icon';
@import './common/button';
@import './common/mixins';
@import '../scrollbar.scss';

/* Sections */

.community-home {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  height: 100vh;
  @import './sections/mainsection';
  @import './sections/sidesection';
}

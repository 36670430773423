@mixin icon($size:$icon-width-md,$int:8px){
    width: $size;
    @include md{
        width:calc(#{$size} - #{$int});
    }
    @include sm{
        width:calc(#{$size} - #{$int} - 4px);
    }
}

@mixin user-icon($size:40px){
    width:$size;
    height:$size;
    background-color: $theme-aqua;
    border-radius: 50%;
}
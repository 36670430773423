@import '../break-points.scss';

.welcome-modal-content2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 75%;
  gap: 20px;

  .profile-picture-container {
    min-width: 85%;

    @include sm {
      width: 100%;
    }

    .profile-picture-text {
      font-size: 20px;
      line-height: 3;
      color: #1e152f;
      font-weight: bold;
      white-space: nowrap;

      @include sm {
        font-size: 17px;
      }
    }
    .profile-picture-image {
      position: relative;
      height: 103px;
      width: 103px;
      // border: none;
      .profile-picture-image-tag {
        overflow: hidden;
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
      .profile-edit-icon {
        cursor: pointer;
        position: absolute;
        bottom: 8px;
        right: 8px;
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 50%;
      }
    }
  }

  .portfolio-container {
    width: 85%;
    overflow-y: scroll;

    @include sm {
      width: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    .portfolio-text {
      font-size: 20px;
      line-height: 3;
      white-space: nowrap;
      color: #1e152f;
      font-weight: bold;

      @include sm {
        font-size: 17px;
      }
    }
    .portfolio-images {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      // justify-content: center;
      align-items: center;
      width: 100%;
      .portfolio-image-border {
        width: 147px;
        height: 128px;
        border-radius: 18px;
        cursor: pointer;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
        }

        @include sm {
          width: 120px;
          height: 100px;
        }
      }

      .portfolio-button {
        width: 147px;
        height: 128px;
        border-radius: 18px;
        border: 1.5px solid #c0c0c0;
        cursor: pointer;

        @include sm {
          width: 120px;
          height: 100px;
        }
      }
    }
  }
}

.crt-com-container {
  width: 100%;
  height: 100%;
  padding: $overall-padding;
  .crt-com-head {
    h1 {
      text-align: center;
      font-size: calc(12px + 0.5vw);
      margin: 2% 0;
      @include sm{
        font-size: calc(16px + 1.2vw);
        text-align: center;
      }
    }
  }
  .crt-com-banner {
    position: relative;
    width: 100%;
    height: 30%;
    border-radius: 10px;
    background-color: $theme-blue-violet;
    @include sm{
      height: 20%;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    .top-widget-container {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1%;
      @include displayFlex(center, flex-end);
      svg {
        width: calc(10px + 0.5vw);
        color: #ffffff;
        margin: 0 5px;
      }
    }
    .banner-camera-icon {
      position: absolute;
      bottom: 1px;
      right: 1px;
      width: 35px;
      height: 35px;
      background-color: $theme-blue-violet;
      border-radius: 50%;
      transform: translate(-35%, -15%);
      @include displayFlex(center, center);
      cursor: pointer;
      svg {
        width: calc(10px + 0.5vw);
        color: white;
      }
    }
    .profile-icon {
      position: absolute;
      bottom: 0%;
      left: 10%;
      transform: translate(0, 50%);
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: $theme-blue-violet;
      border: 2px solid white;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: inherit;
      }
      .camera-icon {
        position: absolute;
        top: 70%;
        right: 0;
        transform: translate(30%, -50%);
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
        border: 1px solid white;
        background-color: white;
        @include displayFlex(center, center);
        cursor: pointer;
        svg {
          width: 70%;
          color: #9c9c9c;
        }
      }
    }
  }
  .com-form {
    width: 100%;
    height: 50%;
    margin-top: 10%;
    @include displayFlex(center, space-between, column);
    @include sm{
      margin-top: 20%;
    }
    .f-int {
      width: 100%;
      background-color: #f2f2f2;
      border-radius: 8px;
      padding: 2%;
      @include displayFlex(flex-start, space-around, column);
      @include sm{
        background-color: white;
        padding: 4%;
      }
      label {
        color: #9c9c9c;
        margin-bottom: 1%;
      }
    }
    .int-name-container {
      position: relative;
      @include sm{
        margin-bottom:2%;
      }
      input {
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        &::placeholder {
          font-weight: 600;
          color: $theme-blue-violet;
        }
      }
      img {
        position: absolute;
        top: 50%;
        right: 3%;
        transform: translate(-50%, -50%);
      }
    }
    .int-desc-container {
      width: 100%;
      @include sm{
        margin-bottom:2%;
      }
      textarea {
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        &::placeholder {
          font-weight: 600;
          color: $theme-blue-violet;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
    .int-lang-container {
      width: 100%;
      background-color: #f2f2f2;
      position: relative;
      border-radius: 8px;
      padding: 2%;
      @include sm{
        background-color: white;
        margin-bottom:2%;
      }
      .lang-input {
        width: 100%;
        @include displayFlex(center, space-between);
        input {
          width: 80%;
          padding: 2% 0;
          border: none;
          outline: none;
          background-color: transparent;
        }
        ul {
          width: 100%;
          list-style-type: none;
          li {
            width: 100%;
            cursor: pointer;
            position: relative;
            @include displayFlex(center, flex-start);
            .lang-content {
              margin-left: 5px;
              p:nth-child(2) {
                color: #9c9c9c;
              }
            }
          }
        }
      }
      .lang-option-container {
        width: 100%;
        height: 20vh;
        position: absolute;
        left: 0;
        border-radius: 10px;
        background-color: white;
        display: none;
        overflow: scroll;
        ul {
          width: 100%;
          list-style-type: none;
          padding: 1% 2%;
          box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.05);
          li {
            width: 100%;
            margin: 1% 0;
            cursor: pointer;
            position: relative;
            @include displayFlex(center, flex-start);
            .lang-content {
              margin-left: 5px;
              p:nth-child(2) {
                color: #9c9c9c;
              }
            }
            .select-lang-icon {
              position: absolute;
              top: 50%;
              right: 5%;
              display: none;
              transform: translate(0%, -50%);
            }
            &:hover .select-lang-icon {
              display: block;
            }
          }
        }
      }
    }
  }
}

@import '../break-points.scss';

.welcome-page-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    z-index: 6;
    width: 90%;
    max-width: 25rem;
    img {
      z-index: 6;
    }
    .createverse-title {
      color: #7c7ef7;
      font-size: 4rem;
      margin: 2% 0;
      z-index: 6;

      @include sm {
        font-size: 3rem;
      }
    }
  }
  .welcome-password-input-container {
    position: relative;
    width: 90%;
    max-width: 25rem;
    display: flex;

    border-radius: 18px;
    height: 2.8rem;
    border-radius: 10px;
    border: 2px solid rgb(196, 196, 196);
    z-index: 6;
    //background: linear-gradient(#c4c4c4, #c4c4c4) padding-box,
    //linear-gradient(90.94deg, #bcbde9 22.28%, #bcbde9 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;

    input {
      width: 100%;
      padding-left: 0.625rem;
      height: 100%;
      border: none;
      outline: none;
      border-radius: inherit;
      background-color: rgba(243, 243, 243, 0.5);
    }
  }
  .error-text {
    font-size: 14.5px;
    z-index: 6;
    margin: 10px;
    color: red;
  }
  .floating-btn {
    position: absolute;
    bottom: 10%;

    @include sm {
      position: initial;
      bottom: initial;
      margin-top: 50px;
    }

    .floating-arrow-bg {
      .floating-arrow {
        animation: floatingAnime 0.6s alternate ease-in-out infinite;
        @keyframes floatingAnime {
          0% {
            margin-right: 5px;
          }
          100% {
            margin-right: 0;
          }
        }
      }
    }
  }
}

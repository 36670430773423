@import '../../../break-points.scss';

.trading-table {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: max(2%, 12px);
  margin: 1rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  white-space: nowrap;
  overflow-x: auto;

  @include sm {
    margin-bottom: 200px;
  }

  .trading-table-head {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: bold;

    @include sm {
      font-size: 9px;
    }
  }

  .trading-table-data {
    font-size: 10px;
    color: #606060;
    margin-right: 10px;

    @include sm {
      font-size: 8px;
    }
  }
}

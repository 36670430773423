@import './postcontent';
@import './widget/filterDropDown';
.post-section-container {
  .post-section-head {
    margin: 2.5rem 0;
    @include displayFlex(center, space-between);
    @include md{
      gap:0;
    }
    @include sm{
      margin: 1.5rem 0;
    }
    .nav-left-arrow,
    .nav-right-arrow {
      cursor: pointer;
    }
    p {
      color: #8b8b8b;
      font-weight: 600;
      cursor: pointer;
      @include lg{
        font-size: calc(0.5vw + 10px);
      }
      @include sm{
        font-size: calc(0.3vw + 8px);
      }
      &.active {
        color: black;
      }
    }
  }
  .add-post-section {
    width: 100%;
    height: 25vh;
    background-color: white;
    border-radius: 15px;
    padding: 0 1rem;
    position: relative;
    @include displayFlex(center, space-between);
    @include sm{
      height: 20vh;
    }
    .add-post-left {
      width: 5%;
      padding: 2rem;
      box-sizing: border-box;
      @media only screen and (max-width: 1500px) {
        padding: 0;
      }
      @include sm{
        width: 15%;
      }
      img {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        cursor: pointer;
        @include sm{
          width: 100%;
          height: auto;
        }
      }
    }
    .add-post-center {
      width: 70%;
      @include displayFlex(center, flex-start);
      @media only screen and (max-width: 1500px) {
        margin-left: 1rem;
      }
      input {
        border: none;
        outline: none;
        font-size: 2rem;
        font-weight: 500;
        color: $shadow-grey;
        width: 100%;
      }
      .whatsnew {
        font-size: 1rem;
        font-weight: 400;
      }
    }
    .add-post-right {
      width: 25%;
      @include displayFlex(center, space-around);
      img{
        @include sm{
          margin: 0 1%;
        }
      }
      &.guest {
        img {
          cursor: not-allowed;
        }
      }
    }
    .post-btn-cont {
      width: 25%;
      padding: 0 2%;
      position: absolute;
      right: -5%;
      bottom: 1.5rem;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1500px) {
        right: -1%;
      }
      @include lg{
        right: 7%;
      }
      @include sm{
        right:5%;
        width: 50%;
        bottom: 5%;
        justify-content: flex-end;
      }
      .eteen-plus-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.08);
        color: #888888;
        height: 1.825rem;
        width: 1.825rem;
        border-radius: 3.125rem;
        font-size: 0.6875rem;
        margin: 0 5%;
        cursor: pointer;
        &.eteen-plus-active {
          background-color: #52caed;
          color: white;
        }
      }
      .post-btn {
        padding: 0.3rem 2.5rem;
        border-radius: 1rem;
        background: linear-gradient(107.9deg, #c7c8ff -42.27%, #7c7ef7 53.1%);
        border: none;
        color: #ffffff;
        height: 2rem;
        cursor: pointer;
        width: 6.0625rem;
        display: grid;
        place-content: center;
        @include sm{
          padding: 0.3rem 1rem;
          width: auto;
        }
        &.guest {
          cursor: not-allowed;
        }
      }
    }
  }
  .post-details-section {
    width: 100%;
    // height: 50vh;
    border-radius: 15px;
    background-color: white;
    margin: 2.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0px;
    @include sm{
      padding: 0.5rem;
      margin: 1.5rem 0;
    }
    .post-details-head {
      width: 100%;
      padding: 1% 2%;
      @include displayFlex(center, space-between);
      @include sm{
        @include displayFlex(center, space-between,column);
        gap: 0.5rem;
      }
      .post-details-left {
        width: 45%;
        @include displayFlex(center, space-between);
        @include sm{
          width: 100%
        }
        button {
          @include gradient-btn;
          @include lg{
            width: fit-content;
            padding: 0.5rem;
            white-space: nowrap;
          }
          @include sm{
            padding: 0.2rem 0.3rem;
          }
          span {
            margin-left: 5%;
          }
        }
        .hrs-btn {
          width: 150px;
        }
      }
      .post-details-right {
        width: 30%;
        @include displayFlex(center, flex-end);
        @media only screen and (max-width: 1300px) {
          width: 40%;
        }
        p{
          @include lg{
            display: none;
          }
        }
      }
    }
    .post-details-body {
      width: 100%;
      padding: 0 5%;
      @include sm{
        padding: 0 3%;
      }
    }
  }
}

.community-menu-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
  padding-bottom: 3.125rem;

  .community-menu-container {
    width: 100%;
    aspect-ratio: 1/0.3;
    padding: 5vh 7vh;
    border-radius: 1.5rem;
    position: relative;
    background-color: white;
    @include displayFlex(center, center);
    flex-direction: column;
    gap: 40px;

    @include sm {
      padding: 3vh 4vh;
    }
    .user-icon {
      @include user-icon(8rem);
      background-color: transparent;
      position: absolute;
      top: -150px;
      left: 70px;
      transform: translate(-5%, 35%);
      img {
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 599px) {
        @include user-icon(5.5rem);
        background-color: transparent;
        top: -100px;
        left: 30px;
      }
    }

    .community-menu-container-top {
      @include displayFlex(flex-start, space-between);
      width: 100%;
      margin-top: 0.35rem;

      .community-menu-container-top1 {
        display: flex;
        flex-direction: column;
        width: 40%;

        @media screen and (max-width: 599px) {
          width: 100%;
        }

        .dob {
          width: 40%;
          display: flex;
          justify-content: center;
          font-size: 0.875rem;
          gap: 0.35rem;
          img {
            width: 0.8125rem;
            height: 0.8125rem;
          }
          span {
            color: #595959;
            font-weight: 400;
          }
          @include sm {
            width: 30%;
            img {
              width: 0.7rem;
              height: 0.7rem;
            }
            span {
              color: #595959;
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
        .profile-quality {
          display: flex;
          align-items: center;
          gap: 0.625rem;
          margin-top: 0.5rem;
          flex-wrap: wrap;
          @include sm {
            flex-wrap: nowrap;
          }

          h2 {
            font-size: 1.5rem;
            @media screen and (max-width: 599px) {
              font-size: 0.9rem;
            }
          }
          .star-rating-position {
            position: relative;

            .star {
              height: 2rem;
              @media screen and (max-width: 599px) {
                height: 1.3rem;
              }
            }
            .star-rating {
              position: absolute;
              top: 9px;
              left: 9px;
              color: white;
              font-size: 0.6563rem;
              @media screen and (max-width: 599px) {
                font-size: 0.4375rem;
                top: 7.5px;
                left: 5.6px;
              }
            }
          }
          .profile-loader {
            display: flex;
            flex-direction: column;
            .profile-loader-top {
              display: flex;
              justify-content: space-between;
              .profile-loader-top1 {
                font-size: 0.625rem;
                @media screen and (max-width: 599px) {
                  font-size: 0.5rem;
                }
              }
              .profile-loader-top2 {
                font-size: 0.625rem;
                @media screen and (max-width: 599px) {
                  font-size: 0.5rem;
                }
              }
            }
            .profile-loader-bottom {
              display: flex;
              align-items: center;
              font-size: 0.625rem;
              gap: 0.625rem;
              @media screen and (max-width: 599px) {
                font-size: 0.5rem;
              }
              .loading-box {
                display: flex;
                height: 0.375rem;
                width: 100px;
                background-color: #e1e1e1;
                border-radius: 5px;
                overflow: hidden;
                .loaded-percent {
                  width: 45%;
                  height: 0.375rem;
                  background-color: #52caed;
                }
                @include sm {
                  width: 70px;
                }
              }
            }
          }
        }
        .tag-name {
          font-size: 0.9375rem;
          line-height: 1.3;
          @media screen and (max-width: 599px) {
            font-size: 0.8rem;
          }
        }
        .talent {
          font-size: 18px;
          line-height: 3;
          color: #909090;
          @media screen and (max-width: 599px) {
            font-size: 0.875rem;
          }
        }
        .followers {
          display: flex;
          align-items: center;
          padding: 0.5rem 0.5rem 1rem 0.5rem;
          p {
            color: #909090;
            padding-left: 5px;
          }
          .followers-image-border {
            position: relative;
            height: 1.9375rem;
            width: 1.9375rem;
            border-radius: 50px;
            overflow: hidden;
            margin-left: -10px;
            border: 3px solid white;
            img {
              position: absolute;
              top: -10px;
              left: -10px;
            }
            @media screen and (max-width: 599px) {
              height: 1.8rem;
              width: 1.8rem;
              border: 2px solid white;
            }
          }
          .remaining-followers {
            @media screen and (max-width: 599px) {
              font-size: 14px;
            }
          }
        }
        .bio-tag-skills {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          @media screen and (max-width: 599px) {
            font-size: 14px;
          }
          #no-data {
            font-weight: 600;
            color: #575368;
          }
          .bio-tag-skills1,
          .bio-tag-skills2,
          .bio-tag-skills3 {
            display: grid;
            grid-template-columns: 10% 80%;
            gap: 1.25rem;

            p {
              width: 3.125rem;
            }
            .bio-tag-skills2 {
              align-items: center;
              height: 1.875rem;
            }

            #hash-tags {
              width: 100%;
              display: flex;
              align-items: center;
              color: #575368;
              gap: 0.5rem;
              flex-wrap: wrap;

              @media screen and (max-width: 599px) {
                font-size: 6px;
              }
              .tag-buttons {
                @include displayFlex(center, center);
                height: 1.25rem;
                width: fit-content;
                padding: 0.5rem;
                font-size: 0.75rem;
                color: white;
                border-radius: 5px;
              }

              .skill-buttons {
                @include displayFlex(center, center);
                height: 1.25rem;
                width: fit-content;
                padding: 0.5rem;
                font-size: 0.75rem;
                color: white;
                border-radius: 5px;
              }
            }
          }
        }
      }
      .community-menu-container-top2,
      .community-menu-container-top3,
      .community-menu-container-top4 {
        @include displayFlex(flex-start, space-between);
        flex-direction: column;
        color: #7c7ef7;
        font-weight: 400;
        h1 {
          font-size: 3rem;
        }

        @include xl {
          h1 {
            font-size: 2rem;
          }
          img {
            height: 50px;
          }
        }

        @include lg {
          h1 {
            font-size: 1.75rem;
          }
          img {
            height: 40px;
          }
        }
        @include md {
          h1 {
            font-size: 1rem;
          }
          img {
            height: 35px;
          }
        }

        .mood-score {
          color: #fe98bf;
        }
        .challenges {
          color: #69bdf9;
        }

        img {
          margin-bottom: 0.3125rem;
        }

        .impact-factor-border {
          @include displayFlex(center, space-evenly);
          margin-top: 10px;
          width: 3.875rem;
          height: 0.9375rem;
          background-color: #7c7ef7;
          border-radius: 5px;
          font-size: 0.4375rem;
          color: white;
          span {
            @include displayFlex(center, center);
            width: 0.375rem;
            height: 0.375rem;
            border-radius: 50%;
            background-color: white;
            color: #7c7ef7;
          }
        }
      }

      @media screen and (max-width: 599px) {
        .community-menu-container-top2,
        .community-menu-container-top3,
        .community-menu-container-top4 {
          display: none;
        }
      }
    }
    .community-menu-container-bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      gap: 1rem;
      .community-icons-border {
        @include displayFlex(center, space-evenly);
        min-width: 4.375rem;
        max-width: fit-content;
        height: 1.875rem;
        border-radius: 12px;
        color: #ffc123;
        @media screen and (max-width: 599px) {
          min-width: 2.3rem;
          height: 1.2rem;
        }

        img {
          margin: 0.3rem;
          height: 0.8125rem;
          @media screen and (max-width: 599px) {
            margin: 0.1rem;
            height: 0.5rem;
          }
        }
        span {
          padding: 0.3rem;
          @media screen and (max-width: 599px) {
            font-size: 10px;
          }
        }
      }
    }
  }
  .community-menu-hall-of-fame,
  .community-menu-groups {
    @include displayFlex(center, space-evenly);
    background-color: white;
    width: 100%;
    border-radius: 34px;
    padding: 30px 0;
    @media screen and (max-width: 599px) {
      padding: 15px 0;
      img {
        height: 1.6rem;
      }
      h2 {
        font-size: 12px;
      }
    }
    p {
      font-size: 0.875rem;
      color: #7c7ef7;
      @media screen and (max-width: 599px) {
        font-size: 0.6rem;
      }
    }
  }
}

@import './variables/variable.scss';
@import './elements/mixins';
@import './elements/breakpoints';
@import './sections/header.scss';
@import './sections/teamschedule.scss';
@import './sections//teamscheduledetails.scss';
@import './sections//sidesection.scss';

.marketPlace{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include md{
        width: 100vw;
    }
    .container {
        min-height: 90vh;
        width:$main-section-width;
        padding: 1.5rem 0 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        @include md{
            margin-left: 0;
            padding: 1rem;
            align-items: center;
            width: 100vw;
            min-height: 80vh;
        }
    }
    @include md{
        flex-direction: column;
        align-items: center;
    }
}
  
@import '../break-points.scss';

.onboarding-page {
  // width: 100vw;
  min-height: 100vh;
  height: 100vh;
  background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lg {
    display: block;
    height: unset;
    padding: 0;
  }
}

.onboarding-container {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  border-radius: 27px;
  overflow: hidden;

  @include lg {
    height: unset;
  }

  .onboarding-content {
    width: 43vw;
  }

  .onboarding-content---left {
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }

    @include lg {
      width: 0;
    }
  }

  .onboarding-content---right {
    min-width: 500px;
    padding: 2.5rem;
    position: relative;
    background-color: white;

    @include lg {
      background-color: transparent;
      min-width: 320px;
      width: 100vw;
    }

    @include sm {
      padding: 2rem;
    }
  }
}

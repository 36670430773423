@mixin displayFlex($align,$justify,$direct:row) {
    display: flex;
    flex-direction: $direct;
    align-items: $align;
    justify-content: $justify;
}

@mixin setSize($value:12px,$set:2px){
    font-size:$value;
    @include md{
        font-size: calc(#{$value} - #{$set});
    }
    @include sm{
        font-size: calc(#{$value} - #{$set}*2);
    }
}
@import './common/variables';
@import '../break-points.scss';
@import './common/icon';
@import './common/button';
@import './common/mixins';
@import '../scrollbar.scss';

/* Sections */
@import './sections/mainsection';
@import './sections/sidesection';

.dashboard {
  width: 100%;
  // height: 100vh;
  display: grid;
  grid-template-columns: 1fr auto;
}

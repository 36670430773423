@import '../../../break-points.scss';

.category-container {
  margin: 2% 0;
  .category-head {
    border-bottom: 1.3px solid #bebebe;
    padding: 20px 0;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: auto;

    @include sm {
      margin: 0;
      border: 0;
    }

    ul {
      list-style-type: none;
      @include displayFlex(center, space-between);
      gap: 10px;
      color: #6f6f6f;

      li:first-child {
        font-weight: bold;
        color: rgb(53, 53, 53);
      }
    }
  }
  .category-body {
    padding: 1% 0;
    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 1rem;
      justify-content: space-around;
      overflow-x: auto;

      li {
        min-width: 180px;
        aspect-ratio: 1/1;
        box-shadow: 0px 4px 12px rgba(36, 33, 50, 0.12);
        border-radius: 10px;
        cursor: pointer;

        @include md {
          min-width: 130px;
        }

        @include sm {
          min-width: 120px;
        }
        .card-head {
          width: 100%;
          height: 25%;
          position: relative;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          .card-logo {
            position: absolute;
            width: 40px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            border: 2px solid white;
            bottom: -10%;
            right: 0;
            z-index: 2;
            transform: translate(-50%, 50%);
            @include displayFlex(center, center);
            h1 {
              font-size: 1.5rem;
              color: white;
            }
          }
        }
        .card-body {
          height: 75%;
          background-color: white;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          clip-path: polygon(0 14%, 100% 1%, 100% 100%, 0% 100%);
          @include displayFlex(flex-end, center);
          .card-body-left {
            flex: 1.5;
            height: 85%;
            padding: 0.5rem;
            @include displayFlex(flex-start, space-between, column);
            .card-left-head {
              h1 {
                font-size: calc(8px + 0.6vw);
              }
              p {
                font-size: calc(8px + 0.5vw);
                color: #888888;
              }
            }
            .card-left-foot {
              p {
                font-size: calc(8px + 0.2vw);
              }
              p:nth-child(1) {
                white-space: nowrap;
              }
              p:nth-child(2) {
                margin-top: 3px;
                color: #888888;
              }
            }
          }
          .card-body-right {
            flex: 1;
            height: 85%;
            padding: 0.5rem 0;
            @include displayFlex(flex-start, space-between, column);
            .card-right-foot {
              p {
                color: #6fcd96;
                font-size: calc(8px + 0.2vw);
              }
            }
          }
        }
      }
    }
  }
}

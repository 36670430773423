@import './break-points.scss';
@import './colors.scss';
@import './scrollbar.scss';

.my-profile {
  display: flex;
  flex-grow: 1;
  flex-direction: column !important;
  gap: 2rem;
  padding-top: 4rem;
  padding-right: 2rem;
  width: 95%;

  @include sm {
    padding-right: unset;
    gap: 1.5rem;
  }

  .icon-btn {
    color: var(--theme-font-color);

    svg {
      &:hover {
        background-color: var(--theme-hover);
      }
    }
  }
  .profile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include md {
      gap: 0.75rem;
    }

    @include sm {
      gap: 0.5rem;
    }

    .profile-header {
      display: flex;
      height: 5rem;
      border-radius: 1rem;
      background: #fff;
      align-items: center;
      gap: 2rem;
      padding-left: 1rem;

      @include md {
        background: none;
        gap: 1rem;
      }

      @include sm {
        padding-left: unset;
        height: 4rem;
        gap: 0.25rem;
      }

      .heading {
        padding: 0.25rem 1rem;
        cursor: pointer;
        font-size: 1.2rem;
        border-radius: 0.5rem;

        @include md {
          font-size: 1rem;
        }

        @include sm {
          font-weight: 600;
          font-size: 0.75rem;
        }

        &.selectedColor {
          color: #6567fe;
          background: rgba(100, 103, 254, 0.11);
        }
      }

      .selected {
        display: block;
        width: 70px;
        height: 110px;
      }

      .hide {
        display: none;
      }

      .vector {
        position: absolute;
        top: 100%;
        left: 8%;

        @include md {
          display: none;
        }
      }
    }

    .profile-container {
      display: flex;
      padding: 3rem;
      background: #fff;
      border-radius: 1rem;
      height: calc(100vh - 12rem - 100px);
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      @include sm {
        padding: 2rem 1rem;
      }

      .details-container {
        background: #f9f9f9;
        border-radius: 12px;
        padding: 2rem;
        height: 100vh;

        @include sm {
          padding: unset;
          background: none;
        }

        .text-field {
          width: 70% !important;

          @include md {
            width: 100% !important;
          }
        }

        .header-text {
          font-size: 1.125rem;
          font-weight: 700;

          @include sm {
            font-size: 1rem;
          }
        }

        .content-header {
          font-size: 0.9375rem;
          font-weight: bold;

          @include sm {
            font-size: 0.8125rem;
          }
        }

        .content {
          font-size: 0.875rem;

          @include sm {
            font-size: 0.75rem;
          }
        }
      }

      .team-container {
        border-bottom: 1px solid #d7d7d7;

        @include xl {
          border-bottom: none;
        }

        .text-container {
          padding: 2rem 0;

          @include md {
            padding: 0.5rem 0;
          }
          .users-text {
            @include md {
              display: none;
            }
          }
        }

        .table-container {
          padding: 2rem 0 2rem 1.5rem;

          table {
            &::-webkit-scrollbar {
              display: none;
            }
          }

          @include xl {
            padding: 1rem 0;
          }

          .table-cell {
            @include sm {
              display: none;
            }
          }
        }

        .header-text {
          font-size: 1.125rem;
          font-weight: 700;

          @include sm {
            font-size: 1rem;
          }
        }

        .content-header {
          font-size: 0.9375rem;
          font-weight: bold;

          @include sm {
            font-size: 0.8125rem;
          }
        }

        .content {
          font-size: 0.875rem;

          @include sm {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

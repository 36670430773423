@import './colors.scss';
@import './break-points.scss';
@import './scrollbar.scss';
@import '~video-react/styles/scss/video-react';

.layout {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'HelveticaNeue', sans-serif;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);

  &.post-connect {
    display: flex;

    .content-container {
      width: calc(100% - 8.5rem);
      height: 100%;
      @include lg {
        width: calc(100% - 6.3rem);
      }
    }
    @media screen and (max-width: 599px) {
      .content-container {
        width: 100%;
        padding: 0.8rem 0.8rem 1rem 0.8rem;
      }
    }
  }
  .toggle-button {
    border-radius: 7.4375rem;
    border: 0.0875rem solid transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(90.94deg, #7c7ef7 22.28%, #fe98bf 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;
  }

  .btn {
    background: #7c7ef7;
    color: #fff;
    text-transform: none;
    font-size: 1rem;
    padding: 0.25rem 0.9rem;
    border-radius: 0.5rem;
    text-align: center;
    &:hover {
      background: #6c6ff5;
    }
    @include lg {
      font-size: 0.75rem;
    }
    @include sm {
      font-size: 0.6rem;
      padding: 0.15rem 0.3rem;
      margin: 0;
      border-radius: 4px;
    }
  }

  .creato-added-anime-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 5;
    &.inactive {
      display: none;
    }
    .confetti-shower-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      .confetti {
        opacity: 0;
        width: 20px;
        height: 30px;
        transform: skew(20deg, 20deg);
        position: relative;
        animation: animeConfetti 3s ease-in-out infinite;
      }
      @keyframes animeConfetti {
        0% {
          opacity: 1;
          margin-top: -100vh;
          margin-left: -200px;
        }
        100% {
          opacity: 1;
          margin-top: 100vh;
          margin-left: 200px;
        }
      }
    }
    .confetti-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .creato-added-msg {
      width: 30vw;
      height: 15vw;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border-radius: 35px;
      box-shadow: 2px 2px 10px 1px rgb(255 207 255 / 60%);

      @include xl {
        width: 35vw;
        height: 20vh;
      }
      @include md {
        width: 45vw;
        height: 20vh;
      }
      @include md {
        width: 70vw;
        height: 20vh;
      }

      .congrats-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
        p {
          font-size: 50px;
          animation: animeCup 2.5s alternate ease-in infinite;
        }
      }
      @keyframes animeCup {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(2);
        }
      }
      .congrats-msg {
        height: 20%;
        p {
          //color:#7c7ef7;
          font-weight: 600;
          font-size: calc(10px + 1vw);
        }
      }
    }
  }
}

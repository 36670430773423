@import '../break-points.scss';

.welcome-modal-content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  min-height: 75%;

  .welcome-modal-header {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;

    .heading {
      font-size: 40px;

      @include lg {
        font-size: 37px;
      }

      @include lg {
        font-size: 28px;
      }
    }
    .hand-wave-icon {
      height: 37px;

      @include sm {
        display: none;
      }
    }
  }
  .tell-us-more {
    font-size: 30px;
    color: #8e8e8e;
    line-height: 2;

    @include sm {
      font-size: 22px;
    }
  }
  .about-me-container {
    padding-top: 60px;
    width: 80%;
    display: flex;
    flex-direction: column;
    // align-items:center;
    gap: 20px;

    @include sm {
      width: 100%;
    }

    .about-me-text {
      font-size: 24px;
      padding-left: 10px;
      span {
        color: #7c7ef7;
      }
    }
    .about-me-text-area {
      height: 200px;
      border: 1.5px solid #f2f2f2;
      background: linear-gradient(0deg, #f2f2f2, #f2f2f2), linear-gradient(0deg, #f9f9f9, #f9f9f9);
      border-radius: 18px;
      padding: 10px;
      &:focus {
        // border:5px solid red;
        outline: 2px solid #7c7ef7;
      }

      @include sm {
        background: white;
      }
    }
  }
}

@import '../scrollbar.scss';
@import '../break-points.scss';

.welcome-modal-content3 {
  min-height: 75%;
  width: 80%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #343434;
  gap: 0.6rem;
  font-weight: bold;

  @include sm {
    width: 100%;
    font-size: 13px;
  }

  //   @extend .scroll-bar-4;
  &::-webkit-scrollbar {
    display: none;
  }
  .heading {
    align-self: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
  .mood-emojies-container {
    display: flex;
    gap: 0 10px;
    margin-bottom: 20px;
    .mood-emoji-border {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eafaff;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      font-size: 12px;
      img {
        cursor: pointer;
      }
      &:hover {
        background: linear-gradient(93.85deg, #7c7ef7 24.33%, #fe98bf 169.93%), #52caed;
      }
    }
    .emoji-active {
      background: linear-gradient(93.85deg, #7c7ef7 24.33%, #fe98bf 169.93%), #52caed;
    }
  }
  .character-container {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;

    @include sm {
      flex-wrap: wrap;
    }

    .character-border {
      display: flex;
      align-items: center;
      gap: 0 4px;
      padding: 2.5px 8px;
      background-color: #eafaff;
      border-radius: 7px;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background: linear-gradient(93.85deg, #7c7ef7 24.33%, #fe98bf 169.93%), #52caed;
        p {
          color: white;
        }
      }
    }
    .character-active {
      background: linear-gradient(93.85deg, #7c7ef7 24.33%, #fe98bf 169.93%), #52caed;
      p {
        color: white;
      }
    }
  }
  .movie-image-nav-container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-left,
    .nav-right {
      cursor: pointer;
    }
    .deactive {
      cursor: not-allowed;
    }
    .movie-image-container {
      display: flex;
      overflow: hidden;
      gap: 0 10px;
      .movie-image {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        width: auto;
        gap: 5px 0;
        .movie {
          height: 94px;
          width: 68px;
          background-position: center;
          background-size: cover;
          border-radius: 5px;
        }
        span {
          top: -5px;
          right: -5px;
          position: absolute;
          width: 13px;
          height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #52caed;
          font-size: 7px;
          border-radius: 50%;
          color: white;
        }
      }
    }
  }
  .book-image-nav-container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-left,
    .nav-right {
      cursor: pointer;
    }
    .deactive {
      cursor: not-allowed;
    }
    .book-image-container {
      display: flex;
      gap: 0 10px;
      .book-image {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        width: auto;
        gap: 5px 0;
        .book {
          height: 94px;
          width: 63px;
        }
        .book-1 {
          height: 94px;
          width: 63px;
          background-image: URL('../../../assets/images/BOOK_1.png');
        }
        .book-2 {
          height: 94px;
          width: 63px;
          background-image: URL('../../../assets/images/BOOK_2.png');
        }
        span {
          top: -5px;
          right: -5px;
          position: absolute;
          width: 13px;
          height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #52caed;
          font-size: 7px;
          border-radius: 50%;
          color: white;
        }
      }
    }
  }
  .book-image-container {
    display: flex;
    gap: 0 10px;

    .book-image {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
      width: auto;
      gap: 5px 0;
      .book {
        height: 94px;
        width: 63px;
      }
      span {
        top: -5px;
        right: -5px;
        position: absolute;
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #52caed;
        font-size: 7px;
        border-radius: 50%;
        color: white;
      }
    }
  }
  .skills-nav-container {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-left,
    .nav-right {
      cursor: pointer;
    }
    .deactive {
      cursor: not-allowed;
    }
    .skill-border {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      font-size: 11px;
      min-width: 53px;
      padding: 2.5px 9px;
      cursor: pointer;
      gap: 9px;

      p {
        color: white;
      }
      img {
        height: 10px;
      }
    }
    .skills-container {
      display: flex;
      margin-bottom: 20px;
      gap: 10px;

      @include sm {
        flex-wrap: wrap;
      }
    }
  }
  .brand-container {
    height: 56px;
    width: 340px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
    gap: 10px 10px;
    .brand-border {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 74px;
      height: 23px;
      padding: 0 5px 0 3px;
      border: 1px solid #7c7ef7;
      border-radius: 5px;
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
        gap: 3px;
        p {
          font-size: 9px;
        }
      }
    }
  }
}

/* Blurred Image - Input */
.blurred-image {
  position: relative;
  .input-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding: 1%;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 15%;
      border-radius: 50%;
    }
    input {
      border: none;
      outline: none;
      width: 50%;
      font-size: 10px;
    }
  }
}
/* Blurred Image - Input Ends Here */

/* Image Action */
.image-action {
  position: relative;
  .action-container {
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, -20%);
    img {
      margin: 0 5%;
      cursor: pointer;
    }
  }
  &:hover .action-container {
    display: flex;
  }
}

.table-container {
  width: 100%;
  min-height: 25vw;
  border-radius: 15px;
  overflow: hidden;
  margin-top: 30px;
  .table-container-head {
    @include displayFlex(center, space-between);
    padding: max(4%, 20px) max(2%, 13px);
    background-color: white;
    border-bottom: 2px solid #f0f3f7;

    @media (max-width: 600px) {
      background-color: transparent;
    }

    .table-head-left {
      flex: 1;
      font-size: calc(8px + 0.1vw);
    }
    .table-head-right {
      flex: 1;
      @include displayFlex(stretch, flex-end);
      .btn {
        padding: max(1%, 3px) max(2%, 8px);
        border: none;
        border-radius: 5px;
      }
      .download-btn {
        background-color: #7c7ef7;
        margin-right: 10px;
        color: white;
        cursor: not-allowed;
        @include displayFlex(center, center);
        font-size: calc(12px);
        svg {
          width: calc(8px + 0.4vw);
          margin-right: 5px;
        }
      }
      .report-btn {
        border: 1px solid #969696;
        background-color: transparent;
        color: #969696;
        padding: max(1%, 3px) max(2%, 8px);
        font-weight: 600;
        cursor: not-allowed;
        font-size: calc(12px);
      }
    }
  }
  .table-container-widget {
    padding: max(3%, 20px);
    background-color: white;
    @include displayFlex(center, space-between);

    @media (max-width: 600px) {
      justify-content: center;
      border-radius: 15px 15px 0 0;
    }

    .table-widget-left {
      ul {
        list-style-type: none;
        display: inline-flex;
        align-items: center;
        border: 1.5px solid #c9c9c9;
        border-radius: 6px;

        .active {
          // background-color: #f5f4fe;
          background-color: #7c7ef7;
          color: white;
          border-radius: 6px 0 0 6px;
        }
        li:nth-child(2) {
          border-left: 1px solid #c9c9c9;
          border-right: 1px solid #c9c9c9;
        }
        li {
          white-space: nowrap;
          color: #757575;
          padding: 8px 16px;
          font-size: calc(12px);
          cursor: pointer;
        }
      }
    }
    .table-widget-right {
      .input-container {
        @include displayFlex(center, space-between);
        border: 1px solid #c9c9c9;
        border-radius: 8px;
        padding: 1% 2%;
        svg {
          width: calc(8px + 0.6vw);
          color: #c9c9c9;
        }
        input {
          border: none;
          outline: none;
          background-color: transparent;
          &::placeholder {
            font-size: calc(8px + 0.4vw);
            color: #c9c9c9;
          }
        }
      }
    }
  }
  .table-body {
    .transaction {
      @include displayFlex(center, flex-start);
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 5%;
        font-size: 1.5rem;
        color: white;
        @include displayFlex(center, center);
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .amount {
      color: #6fcd96;
    }
    .date {
      color: #a2a2a2;
    }
    .category {
      p {
        display: inline;
        width: fit-content;
        border-radius: 10px;
        padding: 2% 4%;
      }
    }
    .wallet-card {
      .wallet-card-container {
        @include displayFlex(center, space-between);
        .wallet-card-left {
          .card-design {
            width: 50px;
            height: 100%;
            padding: 2px 5px;
            border: 2px solid #e6e6e6;
            border-radius: 5px;
          }
        }
        .wallet-card-center {
          @include displayFlex(center, center, column);
          p {
            color: #8f8f8f;
          }
          p:nth-child(2) {
            color: #c2c2c2;
          }
        }
        .wallet-card-right {
          cursor: pointer;
          svg {
            color: #8f8f8f;
          }
        }
      }
    }
  }
}

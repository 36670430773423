@import '../scrollbar.scss';

.left-side-menu {
  display: flex;
  flex-direction: column;
  flex: 1 1 24.5625rem;
  box-shadow: 0rem 0.25rem 0.5625rem rgba(0, 0, 0, 0.19);
  border-radius: 2.25rem 0 0 2.25rem;
  background-color: #ffffff;
  z-index: 1300;
  padding: 3.5rem 1rem 3rem 1rem;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  .search-container {
    height: 3.3125rem;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    .input-with-icon {
      position: relative;
      width: 81%;
      height: 3.3125rem;

      input[type='text'] {
        height: 3.3125rem;
        width: 100%;
        background-color: #eeeeee;
        border-radius: 1rem;
        border: none;
        padding-left: 3.125rem;
      }

      img {
        cursor: pointer;
        position: absolute;
        left: 1.3125rem;
        top: 1.25rem;
        color: #aaa;
        height: 0.9894rem;
        width: 0.9894rem;
      }
      input[type='text']:focus {
        border: none !important;
        outline: none !important;
      }
      input[type='text']::placeholder {
        font-size: 0.9375rem;
        line-height: 1.125rem;
        color: #b0b0b1;
      }
    }
  }

  .members-container {
    width: 100%;
    height: calc(100% - 11.5625rem);

    .members-header {
      height: 2.5rem;
      display: flex;
      gap: 1.25rem;
      justify-content: center;
      margin-bottom: 0.5rem;

      .option {
        align-items: center;
        .label {
          font-size: 1.125rem;
          line-height: 1.3125rem;
          color: #b0b0b1;
          margin-bottom: 0.35rem;
          cursor: not-allowed;

          &.selected {
            color: #1e152f;
            cursor: pointer;
          }
        }
        .dot {
          width: 0.4375rem;
          height: 0.4375rem;
          border-radius: 50%;
          background-color: #7c7ef7;
          margin: 0.3125rem auto 0 auto;
          cursor: pointer;
        }
      }
    }
    .members-list {
      height: calc(100% - 3rem);
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      overflow-y: auto;
      @extend .scroll-bar-4;

      &.no-data {
        justify-content: center;

        #no-data {
          font-weight: 600;
          color: #575368;
        }
      }

      .member-card {
        width: 90%;
        height: 5rem;
        border-radius: 0.8125rem;
        background-color: var(--bgColor);
        display: grid;
        grid-template-columns: 20% 70% 10%;
        align-items: center;
        padding: 0 1rem;
        cursor: pointer;

        .member-avatar {
          display: flex;
          align-items: center;

          #badge {
            width: 0.5375rem;
            height: 0.5375rem;
            border-radius: 50%;
            background-color: #6fcd96;
            margin: 0.3125rem auto 0 auto;
            cursor: pointer;
            position: relative;
            top: 1.125rem;
            left: -1.25rem;
          }
        }

        .member-info {
          padding-left: 0.5rem;

          #name {
            color: #000000;
            font-size: 1.0625rem;
            line-height: 1.25rem;
          }

          #message {
            font-size: 0.875rem;
            line-height: 1rem;
            color: #a4a4a4;
          }
        }

        .message-count {
          #count {
            font-size: 0.9375rem;
            line-height: 1.125rem;
            color: #ffffff;
            background-color: var(--countClr);
            border-radius: 50%;
            padding: 0.15rem 0.45rem;
          }
          #time {
            font-size: 0.875rem;
            line-height: 1rem;
            color: #1f1d28;
          }
        }

        &.live-card {
          grid-template-columns: 60% 40%;
          gap: 0.3125rem;

          .live-info {
            display: flex;
            align-items: center;
            font-size: 1.5rem;
            color: #ffffff;

            #live {
              border: 0.0625rem solid #eeedffc2;
              border-radius: 0.375rem;
              width: 3.5625rem;
              height: 1.375rem;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 0.7rem;

              #live-text {
                font-weight: 600;
                font-size: 0.75rem;
                line-height: 0.875rem;
                color: #ffffff;
              }
            }

            #time {
              font-weight: bold;
              font-size: 0.8125rem;
              line-height: 1rem;
              color: #ffffff;
            }
          }

          .live-contributers {
            display: flex;
            align-items: center;
            justify-content: space-around;

            .contributer {
              .contributer-count {
                height: 1.875rem;
                width: 1.9375rem;
                font-size: 0.9375rem;
                color: #ffffff;
                border: 0.0625rem solid #ffffff;
                border-radius: 50%;
                padding: 0.5rem;
                position: relative;
                left: -0.8125rem;
                bottom: -0.875rem;
                background-color: #52caed;
              }
            }
            #count {
              width: 1.875rem;
              height: 1.1875rem;
              font-size: 0.9375rem;
              line-height: 1.125rem;
              color: #ffffff;
              background-color: #52caed;
              border-radius: 0.625rem;
              padding: 0rem 0.7rem;
            }
          }
        }
      }
    }
  }

  // .options-container {
  //   height: 6.25rem;
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   gap: 1.375rem;

  //   .option {
  //     width: 100%;
  //     display: grid;
  //     grid-template-columns: 20% 80%;

  //     #icon {
  //       text-align: end;
  //       img {
  //         height: 1.25rem;
  //         width: 1.25rem;
  //         cursor: pointer;
  //       }
  //     }
  //     #label {
  //       text-align: start;
  //       font-size: 1.125rem;
  //       line-height: 1.3125rem;
  //       color: #0b0a0f;
  //       cursor: pointer;
  //     }
  //   }
  // }
}

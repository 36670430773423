.community-card-main-container {
  margin: 20px;
  cursor: pointer;
  background-color: white;
  position: relative;
  height: 18.75rem;
  width: 21.875rem;
  border-radius: 1rem;
  overflow: hidden;
  .community-card-cover-image {
    clip-path: polygon(0 0, 100% 0%, 100% 65%, 0% 100%);
    width: 100%;
    height: 45%;
    .cover-image-src {
      width: 100%;
      height: 100%;
    }
  }
  .community-card-profile-image {
    position: absolute;
    right: 1.875rem;
    top: 3.5625rem;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 0.125rem solid #eeeeee;
    overflow: hidden;
    background-color: white;
  }
  .community-card-user-name {
    @include displayFlex(flex-start, flex-start);
    flex-direction: column;
    width: 100%;
    height: 35%;
    padding: 0 1.25rem;
    .organizer {
      color: #888888;
      align-self: flex-end;
      padding-right: 0.8125rem;
      font-size: 15px;
    }
    .community-card-username {
      align-self: flex-end;
      padding-right: 0.8125rem;
    }
    .community-card-purpose {
      text-align: center;
      padding-top: 0.625rem;
      font-weight: bold;
    }
    .community-card-members-count {
      color: #5b5b5b;
      font-size: 14px;
    }
  }
  .community-card-footer {
    width: 100%;
    height: 20%;
    padding: 0 1.25rem;
    @include displayFlex(center, space-between);
    .join-meeting-button {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #7c7ef7;
      font-weight: 400;
      font-size: 15px;
    }
    .community-card-followers-image {
      display: flex;
      align-items: center;
      .community-card-followers-image-border {
        position: relative;
        height:25px;width:25px;
        border-radius:50%;
        overflow: hidden;
        margin-left:-5px;
        img{
          position: absolute;
          top:-10px;
          left:-10px;
        }
      }
      .community-card-extra-followers {
        color: #5b5b5b;
        font-size: 12px;
        padding-left:5px;
      }
    }
  }
}

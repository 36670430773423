@import '../colors.scss';
@import '../scrollbar.scss';
@import '../break-points.scss';

.welcome-modal {
  min-width: 320px;
  min-height: 6.25rem;
  max-height: 95%;
  height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.5rem;
  background-color: white;
  z-index: 10 !important;
  width: 55% !important;
  display: flex;
  overflow: hidden;

  @include lg {
    width: 95% !important;
  }

  @include sm {
    width: 100% !important;
    max-height: unset;
    height: 100% !important;
    border-radius: 0;
    background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);
  }

  // padding-bottom: 1rem;

  .close-icon-button {
    position: absolute;
    right: 30px;
    top: 30px;
    height: 27px;
    width: 27px;
    border: 1.5px solid #7a7a7a;
    .close-icon {
      height: 15px;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    height: 100%;
    // padding: 0rem 2.5rem 2rem 3rem;
    padding: 2rem;
    overflow: auto;
    width: 100%;

    @include sm {
      padding: 2rem 1rem;
    }

    @extend .scroll-bar-5;
    // @media only screen and (max-width: 1024px) {
    //   flex-wrap: wrap;
    //   justify-content: center;
    //   gap: 1rem;
    // }

    .page-indicator {
      width: 100%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .loader {
        height: 17px;
        width: 17px;
        margin: 0 5px;
        border-radius: 50%;
        background-color: #d9d9d9;
        transition-duration: 0.3s;
      }
      .extra {
        width: 79px;
        border-radius: 9px;
        background: #7c7ef7;
      }
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.d-header {
  width: 100%;
  margin-bottom: 2.3rem;
  @include displayFlex(center, space-between);
  .head-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 600px) {
      gap: 0;
    }

    h1 {
      margin-bottom: 10px;
      @include md {
        font-size: 28px;
      }

      @media (max-width: 600px) {
        font-size: 21px;
        margin-bottom: 3px;
      }
    }
    p {
      font-weight: 500;
      font-size: 13px;

      @include sm {
        font-size: 10px;
      }
    }

    .icon-btn {
      color: var(--theme-font-color);

      svg {
        &:hover {
          background-color: var(--theme-hover);
        }
      }
    }
  }
  .head-widget {
    flex: 1;
    @include displayFlex(center, flex-end);
    gap: 12px;
    .icon {
      width: 26px;
    }
    .btn-blue {
      border: none;
      outline: none;
      font-size: 12px;
      padding: 10px 16px;
      border-radius: 10px;
      background-color: $theme-blue;
      color: white;
      white-space: nowrap;
      @include displayFlex(center, center);
      span {
        @include displayFlex(center, center);
        margin: 0 5px;
        img {
          width: 20px;
        }
      }

      @media (max-width: 600px) {
        padding: 5px 10px;
        font-size: 10px;
      }
    }
  }
}

.teamScheduleContainer{
    width: 100%;
}
.teamHeader {
    font-size: 2rem;
    @include md{
      font-size:1rem;
    }
  }
  
  .teamScheduleDatePicker{
    width: 90%;
    margin: 1% 0;
    @include displayFlex(space-between);
    height: 50px;
    @include md{
      width:100%;
    }
  }
  .teamScheduleDatePickerIcon{
    width: 20px;
    height: 20px;
    margin-right: 2%;
    background-color: white;
    @include displayFlex(center);
    border-radius: 5px;
    box-shadow: 0 1px 5px $shadow-grey;
  }
  .teamScheduleDatePickerIcon .angleRight{
    font-size: $p-md-size;
    color: $dark-grey;
  }
  .teamScheduleDateList{
    flex-grow: 1;
    @include displayFlex(space-around);
    list-style-type: none;
    font-size: 12px;
    font-weight: 600;
    color: grey;
    @include sm{
      font-size: $p-sm-size;
    }
    li{
      cursor: pointer;
    }
  }
  .teamScheduleDateList .active{
    padding: 5px 12px;
    color: white;
    background-color: $theme-aqua;
    border-radius: 5px;
  }
  /* Date Picker Ends Here */
  .teamScheduleCardContainer{
    width: 100%;
    height: auto;
  }
  .teamScheduleCardAlign{
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0%;
    @include sm{
      flex-direction: column;
      justify-content: center;
    }
  }
  .teamScheduleCardAlign:nth-child(1){
    justify-content: flex-start;
  }
  .teamScheduleCardAlign:nth-child(2){
    justify-content: flex-end;
  }
  
  .teamScheduleCard{
    margin: 1% 0;
    padding: 5px;
    width: 340px;
    height: 110px;
    box-shadow: 0 3px 5px #D7D7D7;
    border-radius: 5px;
    @include displayFlex(space-between);
    @include md{
      height: 80px;
    }
    @include sm{
      width:90vw;
    }
    div{
      width: 90%;
      height: 90%;
      padding: 0 1%;
    }
  }
  .teamScheduleCard:nth-child(2n-1){
    margin-right: 6%;
    @include sm{
      margin-right: 0;
    }
  }
  .teamScheduleCard .teamScheduleCardIcon{
    flex: 1;
  }
  .teamScheduleCard .teamScheduleCardContent{
    flex: 6;
  }
  .teamScheduleCard .teamScheduleCardImage{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .teamScheduleCardIcon{
    display: flex;
    justify-content: flex-start;
  }
  .teamScheduleCardIcon .cardIconImage{
    width: 40px;
    height: 40px;
    border:none;
  }
  .cardIconImage img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit:cover;
    @include md{
      width: 60%;
      height: 60%;
    }
  }
  .teamScheduleCardContent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .cardContentCategory{
    flex: 1;
    h1{
      width: fit-content;
      font-size: 12px;
      padding: 3.5% 10%;
      border-radius: 5px;
      color:white;
      font-weight: 400;
      @include md{
        font-size: $p-sm-size;
      }
    }
  }
  .cardContentDetails{
    flex: 2;
  }
  .cardContentTitle{
    margin-bottom: 10px;
    font-weight: 600;
    @include md{
      font-size: $p-md-size;
    }
  }
  .cardContentDate{
    font-size: $p-md-size;
    color: grey;
    @include md{
      font-size: $p-sm-size;
    }
  }
  .teamScheduleCardImage{
    padding: 1% 2%;
  }
  .cardImage{
    width: 50%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
  }
  .timeScheduleContainer{
    position: absolute;
    margin-top: 15px;
    margin-left: -3rem;
    left: 0;
    width: 71vw;
    border-top: 2px solid #E1E1E1;
    @include md{
      margin-top: 2rem;
      margin-left: 0;
    }
  }
  .timelineContainer{
    position: absolute;
    top: 0%;
    width: 80%;
    @include md{
      width: 100%;
    }
  }
  .timeline{
    position: absolute;
    top: 0%;
    width: 100%;
    transform: translate(0, -51%);
    @include displayFlex(space-between);
  }
  .timelineTime{
    margin-left: 5%;
    flex-grow: 1;
  }
  .timelineTime p{
    width: fit-content;
    border:1px solid grey;
    background-color: white;
    font-size: $p-sm-size;
    padding: 0 2px;
    border-radius: 3px;
  }
  .timelineTask{
    width: 400px;
    height: 40px;
    border-radius: 5px;
    background-color: $theme-blue;
    @include displayFlex(space-between);
    padding: 0 2%;
    @include md{
      width:200px;
    }
    p{
      font-size: $p-sm-size;
      color: white;
    }
  }
  .aqua{
    background-color: $theme-aqua;
  }
  .timelineTaskContainer{
    width: 70%;
    margin-top:25px;
    display: flex;
    align-items: center;
    justify-content:flex-end;
  }
  /* Team Schedule Details Ends Here */
.community-rules {
  width: 100%;
  .head-container {
    width: 100%;
    border-top: 1px solid #e2e2e2;
    @include displayFlex(center, space-between);
    padding: $overall-padding;
    .link-widget-right {
      position: relative;
      svg {
        color: #9c9c9c;
        cursor: pointer;
      }
      p {
        position: absolute;
        bottom: -100%;
        left: -50%;
        transform: translate(-73%, 10px);
        width: 150px;
        padding: 10px;
        background: #f9f9f9;
        box-shadow: 0px 0px 19px 1px rgb(0 0 0 / 9%);
        border-radius: 5px;
        color: #f6556f;
        cursor: pointer;
        white-space: nowrap;
        text-align: center;
        z-index: 3;
      }
    }
  }
  .input-container {
    width: 100%;
    height: 50%;
    padding: $overall-padding;
    @include displayFlex(center, space-between, column);
    .f-int {
      width: 100%;
      background-color: #f2f2f2;
      border-radius: 8px;
      padding: 2%;
      @include displayFlex(flex-start, space-around, column);
      @include sm{
        background-color: white;
      }
      label {
        color: #9C9C9C;
        margin-bottom: 1%;
      }
    }
    .int-name-container {
      position: relative;
      input {
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        &::placeholder {
          font-weight: 600;
          color: black;
        }
      }
    }
    .int-desc-container {
      width: 100%;
      margin: 4% 0;
      textarea {
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        &::placeholder {
          font-weight: 600;
          color: black;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}

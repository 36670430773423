@import '../scrollbar.scss';
@import '../break-points.scss';

.chat-area {
  display: flex;
  flex: 3 1 calc(100% - 774px);
  // background-color: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  .chat-area-header {
    height: 14rem;
    width: 100%;
    display: grid;
    grid-template-rows: 60% 40%;
    padding: 3rem 2rem 0 2rem;
    border-bottom: 1.3px solid #83848694;

    @include sm {
      padding: 1rem 1rem 0 1rem;
      grid-template-rows: 1fr;
      height: 8rem;
    }

    .hide-scroll {
      max-height: 100%;
      overflow: hidden;
    }

    .profile-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      height: 100%;

      @include sm {
        overflow-x: auto;
        padding-bottom: 17px;
        box-sizing: content-box;
      }

      .profile-image {
        display: flex;
        align-items: center;
        gap: 1rem;

        @include md {
          gap: 0.7rem;
        }

        img {
          height: 5.75rem;
          width: 5.75rem;

          @include md {
            height: 4.5rem;
            width: 4.5rem;
          }

          @include sm {
            height: 3.5rem;
            width: 3.5rem;
          }
        }

        #name-container {
          #name {
            font-size: 2.625rem;
            line-height: 3.0625rem;
            color: #000000;
            white-space: nowrap;

            @include lg {
              font-size: 2rem;
            }

            @include md {
              font-size: 1.5rem;
            }

            @include md {
              font-weight: bold;
              font-size: 1rem;
            }
          }
          .last-seen {
            font-size: 0.9375rem;
            line-height: 1.125rem;
            color: #b0b0b1;

            &.online {
              color: #7c7ef7;
            }

            @include md {
              font-size: 0.8rem;
            }
          }
        }
      }

      .header-menu {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 2rem;

        @include md {
          gap: 1.2rem;
        }

        #icon {
          height: 1.5rem;
          width: 1.5rem;
          cursor: pointer;

          @include sm {
            height: 1.2rem;
            width: 1.2rem;
          }
        }
      }
    }
    .profile-menu-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .chats,
      #icons,
      .friends {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }

      .chats {
        img {
          height: 1.125rem;
          width: 1.125rem;
          cursor: pointer;
        }

        #tag,
        #count {
          font-size: 20px;
          line-height: 23px;
        }

        #tag {
          color: #0b0a0f;
        }

        #count {
          color: #b0b0b1;
        }
      }

      #icons img {
        cursor: pointer;
      }

      .friends {
        gap: 0.7rem;

        #friends-count {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 16px;
          color: #ffffff;
          width: 1.6875rem;
          height: 1.625rem;
          border-radius: 50%;
          background: #7c7ef7;
          padding: 0.3rem;
        }
      }
    }
  }
  .chat-area-body {
    height: calc(100% - 14rem);
    width: 100%;
    padding: 0.5rem 0 3.5rem 2rem;

    @include sm {
      height: calc(100% - 8rem);
    }

    #time {
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #c3c3c5;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .message-container {
      height: calc(100% - 3rem);
      overflow-y: auto;
      @extend .scroll-bar-4;
      padding-bottom: 1.5rem;

      .message {
        display: flex;
        gap: 0.5rem;
        padding-right: 1.5rem;

        #profile-pic {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
        }

        .message-content-container {
          width: 100%;
          margin-bottom: 0.5rem;

          .message-sender {
            display: flex;
            gap: 0.3rem;
            align-items: center;
            margin-bottom: 0.3rem;

            #sender {
              font-size: 0.9375rem;
              line-height: 1.125rem;
              color: #1f1d28;
            }

            #msg-time {
              font-size: 0.8125rem;
              line-height: 0.9375rem;
              color: #717171;
            }
          }

          .message-content {
            display: flex;
            gap: 0.3rem;
            flex-direction: column;

            #content {
              background: #c7c7ff;
              border-radius: 4px 32px 32px 28px;
              min-height: 2.875rem !important;
              padding: 0.5rem;
              width: fit-content;
              align-items: center;
              display: flex;
              font-size: 1rem;
              line-height: 1.1875rem;
              color: #ffffff;
            }

            #image-gallery {
              display: flex;
              gap: 0.5rem;
              flex-wrap: wrap;
            }
          }
        }

        &.my-self {
          flex-direction: row-reverse;

          .message-sender {
            justify-content: end;
          }
          .message-content {
            align-items: flex-end;

            #content {
              background: #7c7ef7;
              border-radius: 32px 4px 28px 32px;
            }
          }
        }
      }
    }

    .message-input-container {
      height: 3rem;
      padding-right: 2rem;

      .input-with-icon {
        position: relative;
        height: 3rem;

        input[type='text'] {
          height: 3rem;
          width: 100%;
          background-color: #eeeeee;
          border-radius: 1rem;
          border: none;
          padding: 0 5.7rem 0 3.125rem;

          @include md {
            background-color: white;
          }
        }

        #profile {
          cursor: pointer;
          position: absolute;
          left: 0.625rem;
          top: 0.5938rem;
          color: #aaa;
        }
        input[type='text']:focus {
          border: none !important;
          outline: none !important;
        }
        input[type='text']::placeholder {
          font-size: 0.9375rem;
          line-height: 1.125rem;
          color: #b0b0b1;
        }
        .message-operations {
          position: absolute;
          top: 0.9375rem;
          right: 0.9375rem;
          display: flex;
          gap: 0.5rem;

          img {
            height: 1.125rem;
            width: 1.125rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@import '../break-points.scss';

.password-recovery-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 70px;

  h1 {
    align-self: flex-start;
    font-size: 1.75rem;

    @include sm {
      font-size: 1.5rem;
    }
  }
  .password-recovery-container {
    width: 100%;
    max-width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    // margin-bottom: 250px;

    label {
      color: #636b76;
      font-size: 1.25rem;
      font-weight: 400;
    }

    .password-recovery-input-container {
      position: relative;
      width: 100%;
      display: flex;

      border-radius: 18px;
      height: 2.8rem;
      border-radius: 1.125rem;
      border: 2px solid rgb(196, 196, 196);
      //background: linear-gradient(#c4c4c4, #c4c4c4) padding-box,
      //linear-gradient(90.94deg, #bcbde9 22.28%, #bcbde9 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;

      input {
        width: 100%;
        padding-left: 0.625rem;
        height: 100%;
        border: none;
        outline: none;
        border-radius: inherit;
      }
      .error-text2 {
        position: absolute;
        bottom: -20px;
        left: 13px;
        font-size: 14.5px;
        color: rgb(255, 86, 86);
      }
    }
  }

  .password-recovery-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    gap: 30px;

    .forgot-password-submit {
      font-size: 18px;
      background: #7c7ef7;
      color: white;
      border-radius: 22px;
      border: none;
      cursor: pointer;
      padding: 10px 15px;
      height: 40px;
      width: 100%;
      max-width: 400px;
    }

    .login-again-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 100%;
      max-width: 400px;
      border: 2px solid #7c7ef7;
      border-radius: 22px;

      color: #7c7ef7;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.redirect-msg-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  line-height: 1.5;

  @include sm {
    //2rem is the padding of onboarding-container--right
    height: calc(100vh - (2 * 2rem));
  }

  p {
    font-size: 15px;
    color: #7c7ef7;
  }

  button {
    background-color: #7c7ef7;
    color: white;
    border: none;
    padding: 12px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
}

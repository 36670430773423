.d-side-section {
  height: 100vh;
  width: 25vw;
  background-color: white;
  border-top-left-radius: 40px;
  padding: 1.5vw 1vw;
  margin-left: 2rem;
  @include displayFlex(center, space-around, column);

  /* Side Head */
  .side-section-head {
    padding-top: 5%;
    width: 90%;
    height: 10%;
    @include displayFlex(center, space-between);
    .side-section-user-profile {
      @include displayFlex(center, flex-start);
      flex: 1;
      .user-icon {
        @include user-icon(4rem);
        margin-right: 2%;
        background-color: transparent;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-detail {
        h1 {
          @include setSize(1rem, 2px);
          margin-bottom: 5px;
        }
        p {
          @include setSize($p-md-size, 2px);
          font-weight: 600;
          /* margin-top: 10px; */
        }
      }
    }
    .side-section-notification {
      @include icon();
    }
  }
  /* Side Body */
  .side-section-body {
    width: 90%;
    height: 60%;
    margin-top: 20px;
    @include displayFlex(center, center, column);
    .recommend-title {
      width: 100%;
      h1 {
        text-align: left;
        @include setSize($p-md-size, 2px);
      }
    }
    /* Card */
    .design-card-container {
      width: 100%;
      flex-grow: 1;
      margin: 10px 0;
      padding: 1% 0;
      overflow: scroll;
      @include displayFlex(center, space-between, column);
      .design-card {
        padding: 1rem;
        width: 95%;
        border: 1px solid #dbdbdb;
        border-radius: 1rem;
        margin: 1% 0;
        .design-card-head {
          margin-bottom: 0.5rem;
          @include displayFlex(center, space-between);
          .design-card-title {
            h1 {
              @include setSize(0.7rem, 1px);
            }
            p {
              @include setSize(8px, 0px);
              color: $dark-grey;
              font-weight: 600;
              margin-top: 5px;
            }
          }
          .design-card-icon {
            img {
              @include icon(14px);
              color: $theme-blue;
            }
          }
        }
        .design-card-foot {
          margin-top: 0.5rem;
          @include displayFlex(center, flex-start);
          p {
            @include setSize($p-md-size, 0px);
            font-weight: 600;
            color: $theme-blue;
          }
        }
      }
    }
    .show-more-container {
      @include displayFlex(center, center);
      .show-more {
        @include setSize(0.7rem, 1px);
        font-weight: 600;
      }
    }
  }
  /* Side Foot */
  .side-section-foot {
    width: 90%;
    height: 30%;
    @include displayFlex(center, space-around, column);
    padding: 0 2%;
    .community-title {
      width: 100%;
      h1 {
        text-align: left;
        @include setSize($p-md-size, 2px);
      }
      .community-icons-container {
        margin-top: 5%;
        @include displayFlex(flex-start, center);
        .community-icons {
          width: 100%;
          @include displayFlex(center, space-between);
          .num46 {
            min-width: 2.3rem;
            min-height: 2.3rem;
            border-radius: 50%;
            border: 2px solid $shadow-grey;
            @include displayFlex(center, center);
            p {
              @include setSize(10px, 1px);
              color: $dark-grey;
            }
          }
        }
      }
    }
    .invite-container {
      width: 100%;
      p {
        @include setSize(14px, 0px);
        font-weight: 600;
      }
      .email-container {
        margin-top: 5%;
        border-radius: 10px;
        position: relative;
        background-color: #f1f1f1;
        input {
          padding: 4% 2%;
          width: 100%;
          border: none;
          outline: none;
          background-color: transparent;
        }
        img {
          position: absolute;
          right: 3%;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.side-bar {
  overflow: hidden;
  width: 6rem;
  height: 100%;
  border-radius: 0 2.5rem 2.5rem 0;
  margin-right: 2.5rem;
  padding: 3.5rem 0;
  -webkit-box-shadow: 2px 2px 10px 1px rgba(255, 207, 255, 0.4);
  -moz-box-shadow: 2px 2px 10px 1px rgba(255, 207, 255, 0.4);
  box-shadow: 2px 2px 10px 1px rgba(255, 207, 255, 0.4);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    margin-right: 2rem;
    width: 4.3rem;
  }

  .main-logo {
    cursor: pointer;
  }
  .container-margin {
    margin-left: 30%;
    z-index: 1;
  }
  .middle-icons {
    height: 50%;

    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      // padding-left: 1.9rem;
      .sidebar2,
      .sidebar3,
      .sidebar4 {
        cursor: pointer;
      }
      #logout {
        display: none;
      }
      li {
        cursor: not-allowed;
        position: relative;
        list-style: none;
        // padding: 57px 23px 57px 31px;
        border-radius: 50%;
        padding: 0.9rem;
        z-index: 1;
        img {
          position: relative;
          height: 25px;
          z-index: 2;
        }
        &:hover .navigate {
          display: block;
          // background-image: url("data:image/svg+xml,%3Csvg width='58' height='123' viewBox='0 0 58 123' fill='none' xmlns='http://www.w3.org/2000/svg%27%3E%3Cpath d='M26.53 88.3427C11.5227 87.142 6.10379e-07 75.4986 0 61.5347C-6.00523e-07 47.7964 11.1644 35.2643 25.8979 33.7838C32.5087 33.1196 39.0562 31.1212 44.0487 26.7372C53.2741 18.6365 58 9.28406 58 0L58 123C58 112.696 51.5073 99.6089 41.2449 92.5382C36.9733 89.5951 31.7008 88.7564 26.53 88.3427Z' fill='%23F6EBF8'/%3E%3C/svg%3E");
          // background-repeat: no-repeat no-repeat;
          // background-position: center center;
          // background-size: cover;
          //background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);
        }
        .navigate {
          display: none;
          position: absolute;
          height: 50px;
          width: 80px;
          top: 0;
          left: 0;
          border-top-left-radius: 40%;
          border-bottom-left-radius: 40%;
          background: #f5ebf8;
          // background: red;
          @media screen and (max-width: 599px) {
            width: 60px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 40%;
            border-bottom-right-radius: 40%;
            top: -18px;
            left: -18.5px;
          }
          &::after {
            content: '';
            width: 40px;
            height: 30px;
            position: absolute;
            top: -30%;
            right: 19%;
            transform: translate(0%, -50%);
            background-color: transparent;
            border-bottom-right-radius: 100%;
            box-shadow: 6px 8px 0 0px #f6ebf8;
            @media screen and (max-width: 599px) {
              top: 5px;
              right: 51px;
              transform: rotate(275deg);
            }
          }
          &::before {
            content: '';
            width: 45px;
            height: 30px;
            position: absolute;
            bottom: -86%;
            right: 25%;
            transform: translate(0, -50%);
            background-color: transparent;
            border-top-right-radius: 100%;
            // box-shadow: 19px -2px 0 #f6ebf8;
            box-shadow: 6px -8px 0 0 #f6ebf8;
            @media screen and (max-width: 599px) {
              bottom: 13px;
              right: -33px;
              transform: rotate(-90deg);
            }
          }
        }
        .active {
          display: block;
        }
      }
    }
  }
}

//mobile view side-bar
.side-bar-mb {
  @extend .side-bar;

  &.mb {
    margin: 0;
    border-radius: 2.5rem 2.5rem 0 0;
    height: 2.8125rem;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 2rem 0;
    z-index: 10;

    #logout-container,
    #logo {
      display: none;
    }

    .container-margin {
      margin: 0;
    }

    .middle-icons {
      height: 100%;
      width: 100%;

      ul {
        flex-direction: row;
        justify-content: space-evenly;

        li {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .side-bar {
    display: none;
  }
  .side-bar-mb {
    display: flex;
    .middle-icons ul #logout {
      display: block;
    }
  }
}

@media screen and (min-width: 600px) {
  .side-bar-mb {
    display: none;
  }
}

@import './common/variables.scss';
@import './common/mixins.scss';
@import '../scrollbar.scss';
.wallet-container{
    display: flex;
    height: 100%;
    button{
        cursor: pointer;
    }
    @import './sections/mainsection/_mainsection.scss';
    @import './sections/tokenDetails/tokenDetails.scss';
    @import './sections/sidesection.scss';
}
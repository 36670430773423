.community-rules-container {
  .community-rules-body {
      width: 100%;
      max-height: 400px;
      margin-top: 2%;
      overflow-y: scroll;
      @include sm{
        max-height: 350px
      }
      @import './communityRules';
  }
  .community-rules-add-btn {
    width: 100%;
    margin: $overall-margin;
    @include displayFlex(center, flex-start);
    @include sm{
      margin: 5%;
    }
    button {
      background-color:$theme-blue-violet;
      color: white;
      padding: 2%;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }
  }
}

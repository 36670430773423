.hw-header {
  margin-bottom: 3.125rem;

  .welcome-container {
    height: 10.625rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include lg {
      gap: 1rem;
      height: auto;
    }

    .welcome-note {
      display: flex;
      align-items: center;
      gap: 1rem;
      .icon-btn {
        color: var(--theme-font-color);

        svg {
          &:hover {
            background-color: var(--theme-hover);
          }
        }
      }
    }

    h1 {
      font-size: 3rem;
      img {
        height: 2.5rem;
      }
      @include lg {
        font-size: 2rem;
        img {
          height: 1.5rem;
        }
      }

      @include md {
        font-size: 1.5rem;
        img {
          height: 1rem;
        }
      }
    }

    .welcome-container-content {
      display: flex;
      gap: 2rem;
      justify-content: space-between;

      @include md {
        img {
          height: 30px;
        }
      }

      @include sm {
        display: none;
      }

      p {
        line-height: 1.5;
        font-weight: 400;

        color: #484848;
      }
      .week-days {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        font-size: 0.875rem;
        .fire-container {
          position: relative;
          p {
            position: absolute;
            top: 10px;
            left: 8px;
            color: white;
          }
        }
        .days-border {
          @include displayFlex(center, center);
          background-color: white;
          border-radius: 3px;
          height: 1.375rem;
          width: 1.375rem;
        }
        .twox {
          color: #ff6c01;
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 599px) {
    .welcome-container {
      gap: 1rem;
      margin-bottom: 1.5rem;
      height: auto;

      .welcome-note h1 {
        font-size: 1.5rem;
        img {
          height: 1.2rem;
        }
      }
    }
  }

  .banner-container {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: 15rem;
      border-radius: 10px;
      object-fit: cover;
    }
    @include lg {
      img {
        height: 10rem;
      }
    }
    @media screen and (max-width: 599px) {
      img {
        height: 8rem;
      }
    }
    #img-edit-btn {
      position: absolute;
      margin: -20px -50px;
      bottom: 20%;
      right: 7%;
      background: transparent !important;
      border: 1px solid #fff;

      @include lg {
        right: 11%;
      }
      @include md {
        right: 14%;
      }
      @include sm {
        right: 18%;
        bottom: 22%;
        height: 2rem;
        width: 2rem;
        .img-edit-icon {
          font-size: 18px;
        }
      }
    }
  }
  .head-widgets {
    width: 100%;
    margin: 1.75% 0;
    @include displayFlex(center, space-between);

    .available-container {
      @include displayFlex(center, space-evenly);
      width: 7.5rem;
      height: 2.1875rem;
      background: white;
      color: #6b7388;
      border-radius: 7px;
      @media screen and (max-width: 599px) {
        height: 1.9rem;
        width: 6rem;
      }

      .online {
        height: 0.625rem;
        width: 0.625rem;
        background-color: green;
        border-radius: 50%;
        @media screen and (max-width: 599px) {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
      .online-text {
        font-size: 0.8rem;
      }
    }
    span {
      display: flex;

      .bell-icon {
        @include icon(1.3rem);
      }
      .join-button-container {
        button {
          @include btn;
          background-color: $theme-blue;
          color: white;
          margin-left: 10px;
          border-radius: 5px;
          font-size: 1rem;
        }
      }
    }
  }
}

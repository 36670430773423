
@import "../wallet/common/mixins.scss";
@import "../wallet/common/variables.scss";
@import '../scrollbar.scss';

.token-creation-container {
  display: flex;
  height: 100%;
  @import "./token-creation-main-section.scss";
  @import "../wallet/sections/_sidesection.scss";
}

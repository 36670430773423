@mixin btn{
    border:none;
    outline: none;
    font-size: $p-md-size;
    padding:0.5rem 1rem ;
    border-radius: 10px;
    @include md{
        padding: 5px 10px;
        font-size: $p-sm-size;
    }
}

@mixin gradient-btn{
    border:none;
    outline: none;
    font-size: $p-md-size + 2;
    padding:0.5rem 1.2rem ;
    border-radius: 5px;
    color:white;
    background: linear-gradient(93.85deg, #7C7EF7 24.33%, #FE98BF 169.93%), #52CAED;;
    @include md{
        padding: 5px 10px;
        font-size: $p-sm-size;
    }
}
.image-cropper-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(22, 22, 22, 0.2);
  z-index: 3;
  .image-pop-up {
    margin: 2%;
    padding: 2% 1%;
    box-shadow: 1px 1px 10px #bababa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    height: 70vh;
    background-color: white;
    position: relative;
    .close-icon{
      position: absolute;
      top:1%;
      right:1%;
      border: 1px solid black;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg{
        width: 15px;
        height: 15px;
      }
    }
    .main-image-container {
      width: 70%;
      height: 100%;
      .react-cropper {
        width: 100%;
        height: inherit;
        div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .image-details-section {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .preview-image-container {
        width: 95%;
        aspect-ratio: 1/0.25;
        border: 1px solid #dbdbdb;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
        .image-preview-title {
          color: #bababa;
        }
      }
      .upload-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 2%;
        button {
          padding: 10px 20px;
          font-size: 1rem;
          background-color: #7c7ef7;
          color: white;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

@import './header';
@import './dashboard-card';
@import './community-card';

.d-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;

  @media (max-width: 600px) {
    padding: 1rem 0;
  }

  .c-body {
    width: 100%;
    overflow-y: auto;
    @include displayFlex(center, space-between, column);
    .c-body-head {
      width: 100%;
      margin-bottom: 1rem;
      @include displayFlex(center, space-between);
      .status {
        @include displayFlex(center, center);
        .status-pointer {
          width: 0.65rem;
          height: 0.65rem;
          margin-right: 5px;
          border-radius: 50%;
          background-color: #6fcd96;
        }
        h1 {
          font-size: 10px;
        }
      }
      .body-widget {
        @include displayFlex(center, space-between);
        p,
        span {
          font-size: 12px;
          @include displayFlex(center, center);
        }
        p:nth-child(1) span {
          margin-right: 0.35rem;
        }
      }
    }
    .c-body-content {
      width: 100%;
      height: 80vh;
      overflow-y: auto;
      display: grid;
      align-items: center;
      align-content: start;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      gap: max(1vw, 1.5rem);
      position: relative; //to center loader

      @media (max-width: 600px) {
        height: 70vh;
        padding-bottom: 3rem;
      }

      #no-data {
        font-weight: 600;
        color: #575368;
      }

      // @include md {
      //   grid-template-columns: auto;
      // }

      &.c-body-loader {
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@import './common/variables';
@import './common//mixins';
@import '../break-points.scss';
@import './common/icon';
@import './common/button';
@import '../scrollbar.scss';
//Sections
@import './sections/mainsection';
@import './sections/sidesection';

.user-profile {
  width: $container-width;
  height: 100vh;
  display: flex;
  justify-content: center;

  @include sm {
    width: 100%;
  }
}

@media screen and (max-width: 599px) {
  .user-profile {
    width: 100%;
  }
}

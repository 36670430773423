@import './colors.scss';
@import './scrollbar.scss';
@import './break-points.scss';

.post-description-container {
  height: 100vh;
  width: calc(100% - 7rem);
  border-radius: 2.1875rem;
  z-index: 3;
  background-color: #ffffff;
  display: grid;
  position: absolute;
  top: 0;
  left: 7rem;
  grid-template-columns: 50% 50%;

  @include lg {
    grid-template-rows: max(50%, 260px) calc(100% - max(50%, 260px));
    grid-template-columns: unset;
  }

  @media (max-width: 600px) {
    width: 100%;
    left: 0;
    border-radius: 0;
  }

  .description-container {
    display: grid;
    grid-template-rows: 18% 60% 30%;
    padding: 2rem 2rem;
    box-shadow: 0rem 0.25rem 0.5625rem rgb(0 0 0 / 19%);
    border-radius: 2.25rem 0 0 2.25rem;
    max-height: 100vh;

    @include lg {
      border-radius: 2.25rem 2.25rem 0 0;
      box-shadow: unset;
      grid-template-rows: max(65px, 20%) 1fr;
      padding: 1rem;
      align-content: space-between;
    }

    @media (max-width: 600px) {
      padding: 0.5rem;
    }

    .description-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;
      width: 100%;

      @include sm {
        align-items: center;
      }

      .user-details {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        .user-img {
          @include sm {
            height: 3rem !important;
            width: 3rem !important;
          }
        }

        .chip {
          border-radius: 0.5rem;
          color: white;
          height: unset !important;
          cursor: pointer;
          font-size: 0.75rem;
          padding: 0.1rem 0;
        }

        .menu-wrap {
          display: flex;
          padding: 0.3125rem;
          color: white;
          background: linear-gradient(102.89deg, #7c7ef7 17%, #52caed 152.44%);
          border-radius: 0.25rem;
          gap: 0.5rem;
          align-self: flex-start;
        }
      }

      .icon-btn {
        color: var(--theme-font-color);

        @include lg {
          display: none;
        }

        svg {
          &:hover {
            background-color: var(--theme-hover);
          }
        }
      }

      .chat-btn {
        color: var(--theme-font-color);

        @include lg {
          display: flex;
          position: absolute;
          top: 1.5rem;
          right: 1rem;
        }

        svg {
          &:hover {
            background-color: var(--theme-hover);
          }
        }
      }
    }

    .description-footer {
      display: flex;
      flex: 0.5 1;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      height: 3.375rem;
      width: 100%;
      align-self: center;

      .icon-grp {
        display: flex;
        gap: 2rem;

        .fav-icon {
          color: #ff93bc;
          background: #fceef6;
          padding: 1rem;
          border-radius: 1.25rem;
        }

        .chat-icon {
          color: #68a6fe;
          background: #ecf3ff;
          padding: 1rem;
          border-radius: 1.25rem;
        }

        .forward-icon {
          color: #7b7ef6;
          background: #f4f4ff;
          padding: 0.75rem;
          border-radius: 1.25rem;
          svg {
            font-size: 2rem;
            transform: rotateY(180deg);
          }
        }
      }
    }
  }

  .comments-container {
    display: grid;
    grid-template-rows: 10% 90%;

    @include lg {
      grid-template-rows: 100%;
    }

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      border-bottom: 1px solid #d1d1e0;

      @include lg {
        display: none;
      }

      .icon-btn {
        color: var(--theme-font-color);

        svg {
          font-size: 2rem;
          &:hover {
            background-color: var(--theme-hover);
          }
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      border-top: 5px solid#f0f0f5;
      justify-content: space-between;
      padding-bottom: 1rem;

      @include lg {
        display: grid;
        grid-template-rows: calc(100% - max(25%, 50px)) max(25%, 50px);
        justify-content: unset;
      }

      @include md {
        grid-template-rows: calc(100% - max(30%, 50px)) max(30%, 50px);
      }

      @include sm {
        grid-template-rows: calc(100% - max(35%, 50px)) max(35%, 50px);
      }

      .comments {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 1.5rem;
        overflow: auto;
        height: 89%;
        @extend .scroll-bar-4;

        @include lg {
          height: unset;
        }
      }

      .message-input-container {
        padding: 0 2rem;
        height: 11%;

        @include lg {
          height: unset;
        }

        .input-with-icon {
          position: relative;
          height: 3rem;

          input[type='text'] {
            height: 3rem;
            width: 100%;
            background-color: #eeeeee;
            border-radius: 1rem;
            border: none;
            padding: 0 5.7rem 0 3.125rem;
          }

          #profile {
            cursor: pointer;
            position: absolute;
            left: 0.625rem;
            top: 0.5938rem;
            color: #aaa;
          }
          input[type='text']:focus {
            border: none !important;
            outline: none !important;
          }
          input[type='text']::placeholder {
            font-size: 0.9375rem;
            line-height: 1.125rem;
            color: #b0b0b1;
          }
          .message-operations {
            position: absolute;
            top: 0.9375rem;
            right: 0.9375rem;
            display: flex;
            gap: 0.5rem;

            img {
              height: 1.125rem;
              width: 1.125rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.horizontal-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 2560px) and (min-width: 960px) {
  .post-description-container {
    .description-container {
      .description-header {
        .chat-btn {
          display: none;
        }
      }
    }
  }
}

@import '../../../break-points.scss';

.main-section-head {
  @include displayFlex(center, space-between, column);
  padding: 1% 0;
  .head-title {
    display: flex;
    gap: 10px;
    width: 100%;
    margin: 20px 0 40px 0;
    align-items: center;

    h1 {
      @include md {
        font-size: 28px;
      }
    }
  }
  .head-widget {
    width: 100%;
    margin-bottom: 12px;
    @include displayFlex(initial, flex-end);
    gap: 1rem;

    button {
      display: inline-block;
      padding: 5px 10px;

      @include sm {
        padding: 2px 6px;
        font-size: 13px;
      }
    }
    .notify {
      border: 1px solid #969696;
      // padding: 10px 12px;
      // margin-right: 15px;
      border-radius: 5px;
      font-weight: 600;
      cursor: pointer;
      span {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #6fcd96;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    .sync {
      background-color: #8d79f6;
      // padding: 10px 12px;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      @include displayFlex(center, space-around);
      svg {
        margin-right: 5px;
        width: 1rem;
      }
    }
  }
}

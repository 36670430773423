@import '../../scrollbar.scss';

.hw-side-section {
  width: 25vw;
  padding: 1rem;
  overflow-y: scroll;
  background-color: white;
  border-top-left-radius: 50px;
  @include lg {
    width: 6vw;
    display: none
  }
  @include displayFlex(center, space-between, column);
  .toggle-widget {
    width: 100%;
    margin: 10px 0;
    @include displayFlex(center, flex-end);
    img {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  }
  .display-hide {
    display: none;
  }
  .side-section-content {
    width: 100%;
    .side-section-head {
      background-color: $theme-grey;
      border-radius: 1rem;
      width: 100%;
      height: 18vh;
      margin: 10px 0;
      padding: 5%;
      @include displayFlex(center, space-between);
      .side-head-left {
        height: 100%;
        @include displayFlex(flex-start, space-around, column);
        h1 {
          @include setSize(1rem, 2px);
          @media only screen and (max-width: 1300px) {
            font-size: calc(10px + 0.2vw);
          }
        }
        .coins {
          color: #69696f;
          font-weight: 600;
        }
        p {
          font-weight: 500;
          color: #a2a2ad;
        }
      }
      .side-head-right {
        img {
          width: 3rem;
          height: 3rem;
        }
      }
    }
    .side-section-community {
      background-color: $theme-green;
      border-radius: 1rem;
      width: 100%;
      height: 45vh;
      margin: 10px 0;
      padding: 5%;
      @include displayFlex(center, space-between, column);
      h1 {
        @include setSize(1rem, 2px);
        @media only screen and (max-width: 1300px) {
          font-size: calc(10px + 0.2vw);
        }
      }
      .side-community-body {
        width: 100%;
        ul {
          padding-left: 5%;
          li {
            margin: 5% 0;
            .side-list-body {
              @include displayFlex(center, space-between);
              .side-body-left {
                @include displayFlex(center, flex-start);
                color: $grey-text;
                img {
                  margin-left: 5px;
                }
              }
              .side-body-center {
                p {
                  color: $grey-text;
                }
              }
              .side-body-right {
                button {
                  @include gradient-btn;
                  padding: 0.5rem 2rem;
                  border-radius: 10px;
                  @media only screen and (max-width: 1300px) {
                    padding: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
      .view-all-btn {
        width: 90%;
        padding: 2% 0;
        background-color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        color: $dark-grey;
      }
    }
    .side-section-premium {
      background-color: $theme-light-pink;
      border-radius: 1rem;
      width: 100%;
      height: 30vh;
      margin: 10px 0;
      padding: 5%;
      @include displayFlex(flex-start, space-around);
      .side-head-image {
        height: 60%;
        @include displayFlex(center, space-around, column);
      }
      .side-head-title {
        height: 100%;
        @include displayFlex(flex-start, space-around, column);
        .side-head-details {
          h1 {
            @include setSize(1.2rem, 2px);
            @media only screen and (max-width: 1300px) {
              font-size: calc(10px + 0.2vw);
            }
          }
          p {
            margin-top: 5px;
            @include setSize(0.7rem, 1px);
            color: rgba(30, 21, 47, 0.68);
          }
        }
        .try-now {
          width: 75%;
          background-color: white;
          font-weight: 600;
          padding: 5% 0;
          border: none;
          outline: none;
          border-radius: 10px;
          @include displayFlex(center, center);
          p {
            background: linear-gradient(180deg, #7c7ef7 0%, #52caed 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
    .side-section-earn {
      background-color: $theme-dark-grey;
      border-radius: 1rem;
      width: 100%;
      height: 35vh;
      margin: 10px 0;
      padding: 5% 3%;
      @include displayFlex(center, space-around, column);
      h1 {
        @include setSize(1rem, 2px);
        text-align: center;
        @media only screen and (max-width: 1300px) {
          font-size: calc(10px + 0.2vw);
        }
      }
      p {
        margin: 0 5%;
        @include setSize(0.8rem, 2px);
        text-align: justify;
        color: #616161;
      }
      .side-earn-btn {
        @include gradient-btn;
        width: 60%;
        white-space: nowrap;
        @include displayFlex(center, center);
        @media only screen and (max-width: 1300px) {
          width: 100%;
        }
        span {
          margin-left: 3px;
        }
      }
    }
  }
}
.toggle-width {
  width: auto;
  overflow-y: auto;
  //animation: animeClose 0.5s ease-in-out forwards;
}

/* @keyframes animeClose{
    0%{
        width: 25vw;
    }
    100%{
        width: 5vw;
    }
} */

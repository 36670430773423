@use 'sass:math';
//Colors
$theme-blue : #7C7EF7;
$theme-orange:#F8C56C;
$theme-aqua:#52CAED;
$theme-lightblue:#EEEDFF;
$theme-pink:#F3D3E8;
$theme-rose:#FF98BF;
$shadow-grey:#B0B0B0;
$dark-grey:#5F5F5F;

//Width Properties
$side-bar-width:8.5rem;
$side-section-width: 20vw;
$main-section-width :calc(100vw - 20vw - 8.5rem);

//Font Size
$p-md-size:12px;
$p-sm-size:8px;
@import './createCommunity';
@import './communityTopics';
@import './socialLinksContainer';
@import './communityRulesContainer';
@import './impInfoContainer';

.create-community-container {
  @import './head/createCommunityHead';
  width: 100%;
  height: 100%;
  .page-scroll {
    @include displayFlex(center, center);
    margin: 1% 0;
    width: 100%;
    height: 5%;
    @include sm{
      display: none;
    }
    p {
      width: 10px;
      height: 10px;
      margin: 0 1%;
      border-radius: 50%;
      background-color: #d9d9d9;
    }
  }
  .create-community-content {
    width: 100%;
    height: 85%;
    @include sm{
      height: 80%;
    }
  }
  .btn-container {
    width: 100%;
    height: 10%;
    padding: 0 5%;
    border-top: 1px solid #e2e2e2;
    @include displayFlex(center, space-between);
    @include sm{
     @include displayFlex(center,space-between,column);
    }
    .prev-btn {
      border: none;
      outline: none;
      color: #b9b9b9;
      font-weight: 600;
      font-size: 14px;
      background-color: white;
      cursor: pointer;
      @include sm{
        display: none;
      }
    }
    .info-prev-btn{
      @include sm{
        display: block;
        width: 100%;
        background-color: #D0D0FF;
        padding: 5%;
        border-radius: 1.5rem;
      }
    }
    .fwd-btn-container {
      width: 40%;
      height: 100%;
      @include displayFlex(center, space-around);
      @include sm{
        width:100%;
        @include displayFlex(center, space-around,column);
        gap:1rem;
      }
      .skip-btn {
        background-color:#ECECFE;
        color: $theme-blue-violet;
        padding: 5% 10%;
        width: 40%;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        @include sm{
          width: 100%;
          background-color: #D0D0FF;
          border-radius: 1.5rem;
        }
      }
      .next-btn {
        background-color: $theme-blue-violet;
        color: white;
        padding: 5% 10%;
        width: 60%;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        margin-left: 5%;
        @include sm{
          width:100%;
          border-radius: 1.5rem;
          margin-left: 0;
        }
      }
    }
  }
}

/* Side Bar */
.sidebar{
    width: $side-section-width;
    height: 100vh;
    margin:1% 0 0 1%;
    background-color: white;
    border-top-right-radius: 50px;
    z-index: 2;
    @include displayFlex(center);
    flex-direction: column;
    padding: 3% 1% 0 1%;
    @include md{
      width: 80vw;
      margin-top: 6rem;
    }
  }
  .analytics{
    width: 100%;
    height: 30vh;
    @include displayFlex(flex-end);
    flex-direction: column;
    position: relative;
  }
  .analyticsHead {
    width: 100%;
    h1{
        font-size: 1vw;
        color: $dark-grey;
        text-align: left;
        margin-left: 5%;
        @include sm{
          font-size: 5vw;
        }
      }
  }
  .analyticsChartContainer{
    width: 100%;
    height: 80%;
  }
  .chartPointerContainer{
    position: absolute;
    top: 50%;
    left: -5%;
    width: 17vw;
    border:1px solid #E8E8E8;
    z-index: -2;
    @include md{
      width:60vw;
      left: 0;
    }
    .chartArrowPointerContainer{
      margin: 0;
      position: absolute;
      top:-50%;
      left:-1%;
      transform: translate(0,-52%);
      font-size: $p-md-size;
    }
    p{
      position: absolute;
      top: -50%;
      left:50%;
      transform: translate(0,-52%);
      font-size: $p-sm-size;
      color:grey;
      background-color: white;
      border:1px solid $shadow-grey;
      border-radius: 3px;
      padding: 1px;
    }
  }
  .analyticsChart{
    margin:2% auto;
    width:90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  .analyticsChartLeft{
    height: 90%;
    padding: 0 1%;
    font-size: $p-md-size;
    color: $shadow-grey;
    ul{
      display: flex;
      flex-direction: column;
      height: 90%;
      justify-content: space-between;
      list-style-type: none;
      padding: 0;
    }
  }
  .analyticsChartRight{
    flex-grow: 1;
    height: 90%;
    padding: 0 1%;
    @include displayFlex(space-between);
  }
  .chartBarContainer{
    width: 18%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    ul{
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      width: 100%;
      height: 90%;
      list-style-type: none;
      padding: 0;
      li{
        width: 30%;
        background-color: $theme-blue;
      }
    }
    p{
      font-size: $p-md-size;
      color:$shadow-grey;
    }
  }
  .chartBarContainer li:nth-child(2){
    background-color: $theme-aqua;
  }
  .sidebarDescription{
    height: 70vh;
    width: 100%;
    padding: 5%;
  }
  .addDesc{
    font-size: $p-md-size;
    color: $dark-grey;
  }
  .addDescTitle{
      font-size: 1.5vw;
      @include md{
        font-size: 3.5vw;
      }
  }
  .mockupImage{
    height: 200px;
    @include displayFlex(center);
    img{
        margin: auto;
        max-width: 100%;
        max-height: 100%;
      }
  }
  .mockupDesc p{
    color:$dark-grey;
    font-size: $p-md-size;
    @include md{
      font-size: $p-md-size + 2;
    }
  }
  .mockupFoot{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 200px;
  }
  .mockupCommunityContainer,.mockupDateContainer{
    margin-right: 1%;
    width: 200px;
    height: 35px;
    font-size: $p-md-size;
    background-color: white;
    display: flex;
    align-items: center;
  }
  .mockupCommunityContainer{
    justify-content: space-between;
  }
  .mockupCommunityIcon,.mockupDateIcon{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    @include displayFlex(center);
    font-size: 1rem;
    color: white;
  }
  .mockupCommunityIcon{
    @include bgColor($theme-blue);
  }
  .mockupDateIcon{
    @include bgColor($theme-orange);
  }
  
  .mockupCommunityContent,.mockupDateContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 8%;
    flex-grow: 1;
    p{
      font-weight: 700;
  }
  }
  .dropDownArrowIcon{
    font-size: 1rem;
    color:$theme-aqua;
  }
  .scheduleButtonContainer button{
    @include scheduleButton;
  }

.d-body-card {
  width: 100%;
  display: flex;
  justify-content: center;
  .user-card {
    width: 100%;
    max-width: 450px;
    aspect-ratio: 1/0.59;
    border-radius: 1rem;
    background-color: white;
    @include displayFlex(center, space-between, column);
    padding: 1rem;
    .user-card-head,
    .user-card-foot {
      width: 100%;
      height: 25%;
      padding: 3%;
    }
    .user-card-head {
      @include displayFlex(center, flex-start);
      .user-icon-container {
        @include user-icon(50px);
        background-color: transparent;
        margin-right: 2%;
        @include displayFlex(center, center);
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .user-details {
        h1 {
          font-size: 16px;
        }
        p {
          margin-top: 2px;
          font-size: 10px;
          color: grey;
        }
      }
    }
    .user-card-body {
      padding: max(3%, 15px);
      h1 {
        font-size: 15px;
      }
      p {
        font-size: 13px;
        padding: 8px 0;
        color: $dark-grey;
      }
    }
    .user-card-foot {
      @include displayFlex(center, space-between);
      .join-button,
      .users-list {
        flex: 1;
      }
      .join-button {
        @include displayFlex(center, flex-start);
        padding: 1% 0;
        .join {
          color: $theme-blue;
          font-weight: 400;
          margin-right: 2%;
        }
        p:nth-child(2) {
          width: 1rem;
          height: 1rem;
          background-color: $theme-blue;
          color: white;
          border-radius: 50%;
          @include displayFlex(center, center);
          @include setSize(0.7rem, 1px);
        }
      }
      .users-list {
        @include displayFlex(center, flex-end);
        .user-profiles {
          flex-grow: 1;
          @include displayFlex(center, center);
          position: relative;
          @for $i from 1 through 4 {
            div:nth-child(#{$i}) {
              right: calc(1rem * #{$i - 1});
              z-index: calc(5 - #{$i});
            }
          }
          .user-available {
            position: absolute;
            @include user-icon(25px);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }
        p {
          @include setSize(0.6rem, 2px);
          color: $dark-grey;
        }
      }
    }
  }
}
// .d-body-card:nth-child(2n) {
//   display: flex;
//   justify-content: flex-end;
//   @include displayFlex(center, center);
// }

.social-links-container {
  @import './socialLinks';
  .socialLinks{
      position: relative;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      @include sm{
        max-height: 340px;
      }
  }
  .add-social-links-btn {
    margin: $overall-margin;
    p {
      cursor: pointer;
      color: $theme-blue-violet;
    }
  }
}

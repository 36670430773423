@import '../break-points.scss';

.draggable-chat-container {
  position: absolute;
  bottom: 10%;
  right: 25%;
  z-index: 1400;
  display: flex;
  justify-content: center;
  align-items: center;
  @import './chatComponent.scss';

  @include xl {
    right: 33%;
  }
  @include lg {
    right: 45%;
  }
  @include md {
    right: 35%;
  }
  @media screen and (max-width: 599px) {
    bottom: 21.5%;
    right: 40%;
  }
  @include sm {
    bottom: 25%;
    right: 60%;
  }
}
.draggable-chat-section {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.com-top-head {
  @include displayFlex(center, space-between, column);
  height: 25%;
  padding: $overall-padding;
  @include sm{
    height: 50%;
  }
  h1 {
    font-size: calc(10px + 0.5vw);
    margin-bottom: 2%;
    @include sm {
      font-size: calc(16px + 1.2vw);
      text-align: center;
    }
  }
  .com-head-banner {
    margin: 2% 0;
    width: 50%;
    height: 50%;
    @include displayFlex(center, center);
    img {
      width: 70%;
      @include sm {
        width: 100%;
      }
    }
  }
  p {
    text-align: center;
    font-size: calc(8px + 0.5vw);
    @include sm {
      color:#383838;
      font-size: calc(12px + 1.2vw);
      text-align: center;
    }
  }
}

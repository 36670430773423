  .header {
    @include displayFlex(space-between);
    width: 90%;
    @include md{
      width: 100%;
    }
  }
  .userDetails{
    width: 25vw;
    @include displayFlex(center);
    p{
      font-family:sans-serif;
      font-weight: 500;
      @include md{
        font-size: $p-md-size;
      }
    }
  }
  .userIcon{
    width: 2rem;
    height: 2rem;
    margin-right:2%;
    border-radius: 50%;
    @include md{
      width: 25px;
      height: 25px;
    }
    img{
        margin: auto;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
 }
  .bellIcon{
    color: $shadow-grey;
    font-size: 1.2rem;
    @include md{
      font-size: $p-md-size;
    }
  }
  .searchInputContainer{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 2%;
    border-radius: 10px;
    @include md{
      width: 50%;
    }
    label{
      color: grey;
      @include md{
        font-size: $p-md-size;
      }
    }
  }
  .searchInput {
    padding: 1rem;
    width: 100%;
    border: none;
    &:focus{
        outline: none;
    }
    &::placeholder{
      font-weight: 500;
      @include md{
        font-size: $p-md-size;
      }
    }
    @include md{
      padding: 10px;
    }
 }
  .textArea:focus {
    outline: none;
  }
.imp-info-container {
  @include sm{
    height: 75vh;
    @include displayFlex(center, space-between, column);
  }
  .imp-info-head {
    @include displayFlex(center, space-between, column);
    height: 25%;
    padding: 5%;
    @include sm{
      height: 50%;
    }
    h1 {
      margin-bottom: 2%;
      font-size: calc(10px + 0.7vw);
      @include sm{
        font-size: calc(16px + 0.5vw);
      }
    }
    .com-head-banner {
      margin: 5% 0;
      width: 200px;
      height: 200px;
      background-color: #f9f9f9;
      border-radius: 50%;
      @include displayFlex(center, center);
      @include sm{
        width: 150px;
        height: 150px;
      }
      img {
        width: 50%;
      }
    }
    p {
      text-align: left;
      font-size: calc(10px + 0.5vw);
      span {
        color: $theme-blue-violet;
      }
    }
  }
  .imp-info-body {
    width: 100%;
    margin: 5% 0;
    @include displayFlex(flex-start, space-around);
    border-top: 1px solid #e2e2e2;
    padding:9% 5%;
    .checkbox-container {
      @include displayFlex(center, flex-start);
      margin-right: 2%;
    }
    .condition {
      flex-grow: 1;
    }
  }
}

@import './tradingTable';
@import '../../common/variables.scss';
@import '../../common/mixins.scss';
@import '../../../scrollbar.scss';
@import '../../../break-points.scss';

.token-details-container {
  width: calc(25vw + 8.5vw);
  flex-grow: 1;
  padding: 2rem 2rem 0 0;
  overflow-y: scroll;

  @include xl {
    width: 100%;
    padding: 1.5rem 1.5rem 0 0;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  .token-details-header {
    display: flex;
    gap: 10px;
    padding: 20px 0 40px 0;
    align-items: center;

    h1 {
      align-items: center;
      display: flex;
      gap: 1rem;

      @include md {
        font-size: 28px;
      }
    }
  }
  .token-details {
    width: 100%;
    min-height: 100%;
    border-radius: 15px;
    position: relative;
    background-color: #f8f8ff;

    &.no-data {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .banner-container {
      position: relative;
      img {
        width: 100%;
      }
      .token-icon {
        position: absolute;
        bottom: 0%;
        left: 10%;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        transform: translate(0, 20%);
        @include displayFlex(center, center);
        z-index: 3;
        border: 2px solid white;
        background-color: black;
        p {
          font-size: 2.5rem;
          font-weight: 600;
          color: white;
          cursor: pointer;
        }
      }
    }
    .banner-wrapper {
      width: 100%;
      height: 350px;
      border-radius: 25px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      clip-path: polygon(0 0, 100% 0, 100% 52%, 0 93%);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 52%, 0 93%);
      z-index: 1;
      position: relative;
    }
    .banner-wrapper-img {
      width: 100%;
      height: 150px;
      background-color: black;
      position: absolute;
      bottom: 1.3rem;
      clip-path: polygon(0 75%, 100% 0, 100% 4%, 0 100%);
      -webkit-clip-path: polygon(0 75%, 100% 0, 100% 4%, 0 100%);
      z-index: 2;
    }
    .token-icon {
      position: absolute;
      bottom: 0;
      left: 6%;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      transform: translate(0, 20%);
      @include displayFlex(center, center);
      z-index: 3;
      border: 2px solid white;
      background-color: black;
      p {
        font-size: 3.625rem;
        font-weight: 600;
        color: white;
        cursor: pointer;
      }
    }
    .kabali-banner {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      img {
        width: 100%;
      }
    }
    .token-header-container {
      margin-bottom: 4rem;
    }

    .token-content-container {
      padding: max(3%, 15px);
      .about-section {
        max-width: 100%;
        h1 {
          margin: 15px 0;
          font-size: 20px;
          font-weight: 500;
        }
        p {
          background-color: white;
          border-radius: 10px;
          padding: max(2%, 15px);
          color: #717171;
        }
      }
      .full-token-information {
        margin: 30px 0;
        h1 {
          margin: max(3%, 15px) 0;
          font-size: 20px;
        }
        .token-summary {
          h1 {
            font-size: 16px;
            font-weight: 400;
          }
          p {
            background-color: white;
            border-radius: 10px;
            padding: max(2%, 15px);
            color: #717171;
          }
        }
        .token-supply {
          margin: 2rem 0;
          h1 {
            font-size: 17px;
            font-weight: 500;
          }
          p {
            color: #717171;
          }
        }
      }
      .trading {
        h1 {
          font-size: 17px;
          font-weight: 500;
        }
        .trading-chart {
          width: 100%;
          height: 40vh;
          margin: 30px 0;
          padding: max(15px, 2%);
          background-color: white;
          border-radius: 10px;
          p:nth-child(1) {
            color: #494949;
          }
          p:nth-child(2) {
            margin-top: 5px;
            font-weight: 600;
          }
          .rechart-component {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

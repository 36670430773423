@import '../break-points.scss';

.user-container {
  // display: flex;
  // flex-direction: column;
  // align-content: center;
  // justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .user-container-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    // overflow: auto;

    @include lg {
      gap: 3rem;
    }

    .profile-header {
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.01em;
      color: #1e152f;

      @include sm {
        font-size: 25px;
      }
    }

    .profile-describe {
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      gap: 1rem;
      margin-top: 1.5rem;

      .describe-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #666779;
      }

      .profile-describe-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 1rem;
      }
    }

    .profile-content {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 1.5rem;
      position: relative;
      flex-wrap: wrap;

      @include sm {
        gap: 0.8rem;
      }

      input {
        outline: none;
        margin-bottom: 5px;
      }

      .error-text {
        position: absolute;
        color: red;
        font-size: 14px;
        bottom: -11px;
        margin-left: 10px;
      }

      .profile-content-text-box {
        width: calc(40% - 1.5rem);
        text-align: end;

        @include sm {
          width: calc(30% - 0.8rem);
          text-align: start;
        }

        @media (max-width: 400px) {
          white-space: nowrap;
          width: 100%;
        }
      }

      .profile-content-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #666779;

        @include sm {
          font-size: 16px;
        }
      }

      .skills-content {
        .skill-arrow {
          display: none;
        }

        &:hover {
          .skill-arrow {
            display: block;
          }
        }
      }

      .profile-content-input-box {
        width: 60%;
        min-width: 225px;
        text-align: start;

        @include sm {
          width: 70%;
        }
      }

      .profile-input {
        border-radius: 18px;
        min-width: 166px;
        padding: 0.5rem 1rem;
        height: 35px;
        border: 1.4px solid #c4c4c4;
        // border: solid 1.4px transparent;
        // background: linear-gradient(#ffffff, #ffffff) padding-box,
        //   linear-gradient(90.94deg, #7c7ef7 22.28%, #fe98bf 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;
      }

      .profile-select {
        border-radius: 18px;
        min-width: 52px;
        padding: 0.5rem 1rem;
        text-align: center;
        height: 31px;
        outline: none;
        border: 1.4px solid #c4c4c4;
        // border: solid 1.4px transparent;
        // background: linear-gradient(#ffffff, #ffffff) padding-box,
        //   linear-gradient(90.94deg, #7c7ef7 22.28%, #fe98bf 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;
      }
      .skill-button-container {
        // justify-content: center;
        // align-items: flex-start;
        height: 27.9px;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        width: 218px;
        padding-top: 2px;
        overflow: hidden;
        flex-wrap: wrap;
        button {
          min-width: 65px;
          min-height: 22.9px;
          padding: 3px 7px;
          // background: #52caed;
          border-radius: 11px;
          border: none;
        }
      }
      .skills-button {
        min-width: 65px;
        min-height: 22.9px;
        padding: 3px 7px;
        background: #52caed;
        border-radius: 11px;
        border: none;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .back-arrow {
      img {
        cursor: pointer;
        padding: 12px 10px;
        border: 1.5px solid #636b76;
        border-radius: 32px;
      }
    }

    button {
      background: #7c7ef7;
      color: white;
      border-radius: 22px;
      border: none;
      cursor: pointer;
      padding: 10px 15px;
      gap: 10px;
      img {
        height: 10px;
      }
    }
  }
}

.custom-border-button {
  border-radius: 18px;
  width: 30%;
  padding: 12px;
  cursor: pointer;
  border: 1.4px solid #c4c4c4;
  // border: solid 1.4px transparent;
  // background: linear-gradient(#ffffff, #ffffff) padding-box,
  //   linear-gradient(90.94deg, #7c7ef7 22.28%, #fe98bf 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #9c9ea9;
}

.skillModalBackground {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;

  .skillModalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    height: 30%;
    width: 300px;
    .closeButton {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
    }
    .skill-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      height: 100%;
    }
    .popup-input {
      border-radius: 18px;
      min-width: 245px;
      padding: 0.5rem 1rem;
      height: 47px;
      outline: none;
      border: solid 1.4px transparent;
      background: linear-gradient(#ffffff, #ffffff) padding-box,
        linear-gradient(90.94deg, #7c7ef7 22.28%, #fe98bf 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;
    }
    .popup-button {
      box-sizing: border-box;
      min-width: 245px;
      height: 47px;

      background: linear-gradient(95.49deg, #7c7ef7 11.35%, #fe98bf 163.04%);
      border-radius: 18px;
      border: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
    }
  }
}

.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  height: inherit;
  width: 300px;
  .closeButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  .popup-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
  }
  .popup-input {
    border-radius: 18px;
    min-width: 245px;
    padding: 0.5rem 1rem;
    height: 47px;
    outline: none;
    border: solid 1.4px transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(90.94deg, #7c7ef7 22.28%, #fe98bf 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;
  }
  .popup-button {
    box-sizing: border-box;
    min-width: 245px;
    height: 47px;

    background: linear-gradient(95.49deg, #7c7ef7 11.35%, #fe98bf 163.04%);
    border-radius: 18px;
    border: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
  }
}

@import '../break-points.scss';

.registration-login-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;

  @include lg {
    max-width: 60vw;

    h2 {
      margin-bottom: 10vh;
    }
  }

  @include sm {
    max-width: unset;
  }

  h2 {
    color: black;
    font-weight: 600;
    line-height: 3;
  }

  .registration-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .registration-header {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 1rem;
      overflow: auto;

      @include lg {
        gap: 3rem;
      }

      .error-text {
        position: absolute;
        top: 41px;
        left: 15px;
        font-size: 14.5px;
        color: rgb(255, 86, 86);
      }

      .input-field {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 2.5rem;
        border-radius: 1.125rem;
        border: 1.4px solid transparent;
        background: linear-gradient(#c4c4c4, #c4c4c4) padding-box,
          linear-gradient(90.94deg, #bcbde9 22.28%, #bcbde9 122.25%, rgba(188, 189, 233, 0) 122.26%) border-box;

        input {
          border: transparent;
          outline: transparent;
          width: 100%;
          height: 100%;
          border-radius: 1.125rem;
          padding-left: 0.9375rem;
        }

        select {
          height: 100%;
          min-width: 55px;
          border: none;
          border-right: 1px solid rgb(168, 168, 168);
          color: #7e7e7e;
          background: white;
          font-weight: 500;
          font-size: 0.9375rem;
          border-radius: 1.25rem 0 0 1.25rem;
          outline: none;
          padding-left: 1.5%;
        }

        .phone-number {
          border-radius: 0 18px 18px 0;
        }
      }

      .bottom-text {
        line-height: 1.5;
        text-align: center;

        span {
          color: #7c7ef7;
        }
      }
    }
  }
  .button-field {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .back-arrow {
      img {
        cursor: pointer;
        padding: 12px 10px;
        border: 1.5px solid #636b76;
        border-radius: 32px;
      }
    }

    button {
      margin: 6px 0;
      background: #7c7ef7;
      color: white;
      border-radius: 22px;
      border: none;
      cursor: pointer;
      padding: 10px 15px;
      img {
        height: 10px;
      }
    }

    .bottom-text {
      line-height: 31;
      text-align: center;
    }
  }

  // sign in page

  .sign-in-page-header {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #636b76;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;

    @include lg {
      gap: 3rem;
    }

    .sign-in-username-container,
    .sign-in-password-container {
      position: relative;
      width: 85%;
      display: flex;
      flex-direction: column;
      gap: 18px;

      label {
        font-size: 1rem;
        font-weight: 400;
      }
      .error-text2 {
        position: absolute;
        bottom: -20px;
        left: 13px;
        font-size: 14.5px;
        color: rgb(255, 86, 86);
      }

      .sign-in-username-input {
        width: 100%;
        input {
          border-radius: 1.125rem;
          padding: 12px 0.625rem;
          border: 1px solid #bcbde9;
          outline: none;
          width: 100%;
        }
      }

      .sign-in-password-input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 18px;
        height: 40px;
        background-color: white;
        border: 1.4px solid #bcbde9;
        input {
          width: 90%;
          padding-left: 0.625rem;
          height: 100%;
          border: none;
          outline: none;
          border-radius: inherit;
          background-color: inherit;
        }
        img {
          padding-right: 4px;
          cursor: pointer;
          height: 60%;
        }
      }
    }
    .sign-in-user-agreement {
      position: relative;
      width: 80%;
      display: flex;

      #user-agreement-p {
        font-size: 17px;
        color: #a5a5a5;
      }

      .checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 10px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .checkmark {
            background-color: #7c7ef7;
          }
          &:checked ~ .checkmark:after {
            left: 6.5px;
            top: 4px;

            width: 3px;
            height: 7px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #fff;
          border-radius: 15px;
          outline: 1px solid #7c7ef7;
          outline-offset: -1px;

          &:after {
            content: '';
            position: absolute;
          }
        }
      }

      .error-text-accept-terms {
        position: absolute;
        bottom: -20px;
        left: 13px;
        font-size: 14.5px;
        color: rgb(255, 86, 86);
      }
    }

    .sign-in-note {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 17px;
      #sign-in-note-p {
        line-height: 1.5;
        color: #969696;
        margin-bottom: 10px;
      }
    }
  }
}

// Palette Colors
$color-primary: #EC3C93;
$color-secondary: #cbdb2a;
$color-grey: #6d7278;
$color-gray: #c1c0c0;
$color-light-grey: #d8d8d8;
$color-light-gray: #d3d3d3;
$color-lighter-grey: #6d72781a;
$color-white: #ffffff;
$color-loading-style: #a5a5a5;
$color-black: #000000;

// Toast Colors
$color-toast-success: #c1c0c0;
$color-toast-info: #2196f3;
$color-toast-warn: #ff9800;
$color-toast-error: #EC3C93;
$color-toast-dark: #313131;

.dark {
  --theme-font-color: #ffffff;
  --theme-background-color: #211E20;
  --theme-dashboardbg-color: #342f30;
  --theme-disabledbtnbg-color:#313131;
  --theme-palette-bg-color: #ffffff;
  --theme-palette-oval-color: #000000;
  --theme-toggle-btn-color: #fff;
  --theme-palette-ovalbg-color: #d8d8d8;
  --theme-oval-font-color: #ffffff;
  --theme-light-grey: #6d7278;
  --theme-hover: #313131;
  --theme-header-border-color: #6d7278;
  --theme-divider-border-color: #313131;
  --theme-big-divider-border-bottom-color:#272626;
  --theme-big-divider-border-top-color:#313131;
  --theme-table-header-bg: #6d72781a;
  --theme-drop-arrow-color: #6d7278;
  --theme-landing-page-bg:#211E20;
  --theme-list-btn-color:#ffffff;
}

.light {
  --theme-font-color: #000000;
  --theme-background-color: #ffffff;
  --theme-dashboardbg-color: #f7f7f7;
  --theme-disabledbtnbg-color:#0000001f;
  --theme-palette-bg-color: #6d72781a;
  --theme-palette-oval-color: #ffffff;
  --theme-palette-ovalbg-color: #000000;
  --theme-toggle-btn-color: #d8d8d8;
  --theme-oval-font-color: #000000;
  --theme-light-grey: #d8d8d8;
  --theme-divider-border-color: #5152531a;
  --theme-big-divider-border-bottom-color:#c1c0c0;
  --theme-big-divider-border-top-color:#d8d8d8;
  --theme-hover: lightgrey;
  --theme-header-border-color: #5152531a;
  --theme-table-header-bg: #f5f5f5;
  --theme-drop-arrow-color: #000000;
  --theme-landing-page-bg:#ffffff;
  --theme-list-btn-color:#92878e;
}

@import '../../../break-points.scss';

.main-section-container {
  flex-grow: 1;
  height: 100%;
  padding: 1% 1% 0 1%;
  overflow-y: scroll;
  overflow-x: hidden;

  @include md {
    padding: 0 0 180px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @import './head';
  @import './chart';
  @import './category';
  @import './table';
}

@import '../break-points.scss';

.token-creation-main-section-container {
  flex-grow: 1;
  padding: 1% 1.5% 0 1%;
  overflow-y: auto;
  height: 100%;

  @media (max-width: 600px) {
    padding-bottom: 80px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  .token-creation-heading {
    padding: 50px 0;
    .back-icon {
      margin-right: 10px;
    }
    white-space: nowrap;

    @include md {
      font-size: 28px;
    }
  }
  .token-creation-form {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 35px 30px 45px;
    border-radius: 33px;

    @include sm {
      padding: 0 20px 20px 20px;
    }

    .token-creation-card-heading {
      display: flex;
      div {
        flex: 1;
        padding: 30px 0 10px 40px;
        color: #686868;
        font-size: 14px;
        font-weight: 400px;
      }
    }
    .token-creation-card-container {
      @include displayFlex(center, space-evenly);
      width: 100%;
      padding: 20px 0;

      @include lg {
        justify-content: center;
      }

      .token-creation-card-body {
        width: 350px;
        height: 240px;
        position: relative;
        .creato-stalk-card {
          position: absolute;
          top: 50%;
          left: 50%;
          border: 1px solid white;
          border-radius: 11px;
          padding: 5% 3%;
          @include displayFlex(flex-start, space-between);
          -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
          box-shadow: 2px 2px 5px #c7c7c7;
          cursor: pointer;
          margin-bottom: 20px;
          &:hover {
            top: 47%;
            transition: 0.1s ease-in-out;
          }
          h3 {
            color: white;
            padding-top: 8px;
            font-size: calc(9px + 0.5vw);
          }
          .square {
            width: 60px;
            height: 34px;
            border: 1px solid #ffffff;
            border-radius: 6px;
            opacity: 0.4;
            background: linear-gradient(0deg, rgba(238, 238, 238, 0.27), rgba(238, 238, 238, 0.27)), linear-gradient(0deg, #ffffff, #ffffff);
          }
        }
        .card-2,
        .card-3 {
          &:hover {
            top: 40%;
          }
        }
        @for $i from 1 through 3 {
          .card-#{$i} {
            width: calc(100% - (10% * #{$i}));
            height: 70%;
            transform: translate(-50%, calc(-30% - (10% * #{$i})));
            z-index: calc(4 - #{$i});
          }
        }
      }
      .three-dots {
        font-size: 35px;
        color: #afafaf;
        margin: 0 -50px 0 -70px;
      }
      .blue-card {
        width: 340px;
        height: 200px;
        border-radius: 11px;
        padding: 3% 2%;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 2px 2px 5px #c7c7c7;
        @include displayFlex(flex-start, space-between);
        h3 {
          color: white;
          padding-top: 8px;
          font-size: calc(10px + 0.5vw);
        }
        .square {
          width: 83px;
          height: 47px;
          border: 1px solid #ffffff;
          border-radius: 6px;
          opacity: 0.4;
          background: linear-gradient(0deg, rgba(238, 238, 238, 0.27), rgba(238, 238, 238, 0.27)), linear-gradient(0deg, #ffffff, #ffffff);
        }
      }
    }
    form {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      gap: 25px;
      .input-field {
        display: flex;
        flex-direction: column;
        gap: 13px;
        label {
          font-size: 14px;
          font-weight: 400;
        }
        .MuiOutlinedInput-root {
          border-radius: 9px;
          outline: 2px solid #c4c4c4;
          height: 50px;
          font-size: 12px;
        }
        .text-field-supply {
          width: 100%;
        }
        .select-supply {
          right: 0;
          width: 150px;
          color: #636b76;
        }
      }
      .token-agreement {
        cursor: pointer;
        display: flex;
        font-size: 12px;
        border: 2px solid #52caed;
        border-radius: 9px;
        color: #52caed;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        font-weight: 400;
        margin: 10px 0;
        gap: 10px;

        @include sm {
          flex-direction: row-reverse;
        }

        p {
          max-width: 460px;
        }
      }
      .token-creation-button {
        cursor: pointer;
        width: 100%;
        height: 53px;
        border-radius: 23px;
        color: white;
        font-size: 20px;
        font-weight: 400;
        border: none;
        background: linear-gradient(0deg, #7c7ef7, #7c7ef7), linear-gradient(0deg, #bcbde9, #bcbde9),
          linear-gradient(107.9deg, #c7c8ff -42.27%, #7c7ef7 53.1%);
      }
    }
  }
}

@import '../break-points.scss';

.chat-container {
  height: 100%;
  width: calc(100% - 7rem);
  border-radius: 2.1875rem 0 0 2.1875rem;
  z-index: 1300;
  background-color: #ffffff;
  display: flex;
  position: absolute;
  top: 0;
  left: 7rem;

  @include md {
    width: 100%;
    left: 0;
    border-radius: 0;
    background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);
  }
}

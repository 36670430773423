.filter-btn-container {
  position: relative;
  button {
    width: 150px;
    margin-left: 10px;
    @include gradient-btn;
    span {
      margin-left: 5%;
    }
  }
  .filter-24-hrs {
    @media only screen and (max-width: 1300px) {
      width: 150px !important;
    }
    @include lg {
      width: 80px;
    }
  }
  ul {
    position: absolute;
    width: 95%;
    min-height: 100%;
    z-index: 3;
    left: 0;
    list-style-type: none;
    background-color: white;
    transform: translate(10px, -5px);
    border: 1px solid #bdbdbd;
    @include sm{
      transform: translate(5%, 1%);
    }
    li {
      margin: 10px 5px;
      cursor: pointer;
      @include sm{
        font-size: calc(8px + 0.3vw);
      }
      &::hover {
        background-color: blue;
      }
    }
  }
}

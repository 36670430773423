.d-header {
  width: 100%;
  margin-bottom: 2.3rem;
  @include displayFlex(center, space-between);
  flex-wrap: wrap;

  @include sm {
    gap: 12px;
  }

  .head-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    @include sm {
      width: 100%;
    }

    h1 {
      margin-bottom: 10px;
      @include md {
        font-size: 28px;
      }

      @media (max-width: 600px) {
        font-size: 21px;
        margin-bottom: 3px;
      }
    }
    p {
      font-weight: 500;
      font-size: 13px;

      @include sm {
        font-size: 10px;
      }
    }

    .icon-btn {
      color: var(--theme-font-color);

      svg {
        &:hover {
          background-color: var(--theme-hover);
        }
      }
    }
  }
  .head-widget {
    flex: 1;
    @include displayFlex(center, flex-end);
    gap: 12px;
    .icon {
      width: 26px;
    }
    .btn-blue {
      border: none;
      outline: none;
      font-size: 12px;
      padding: 10px 16px;
      border-radius: 10px;
      background-color: $theme-blue;
      color: white;
      white-space: nowrap;
      @include displayFlex(center, center);
      span {
        @include displayFlex(center, center);
        margin: 0 5px;
        img {
          width: 20px;
        }
      }

      @media (max-width: 600px) {
        padding: 5px 10px;
        font-size: 10px;
      }
    }

    .search-container {
      position: relative;
      // display: inline-block;
      display: flex;
      margin: 4px 2px;
      height: 40px;
      max-width: 50px;
      width: 100%;
      vertical-align: bottom;

      .button {
        display: inline-block;
        margin: 4px 2px;
        font-size: 14px;
        padding-left: 32px;
        padding-right: 32px;
        height: 100%;
        line-height: 50px;
        text-align: center;
        color: white;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
      }

      .mglass {
        display: inline-block;
        pointer-events: none;
      }

      .searchbutton {
        position: absolute;
        font-size: 22px;
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 10px 10px 0;
      }

      .search:focus + .searchbutton {
        transition-duration: 0.4s;
        background-color: white;
        color: black;
      }

      .search {
        position: absolute;
        left: 49px;
        background-color: white;
        outline: none;
        border: none;
        padding: 0;
        width: 0;
        height: 100%;
        z-index: 10;
        transition-duration: 0.4s;
      }

      .search:focus {
        border-radius: 10px 0 0 10px;
        width: 163px; /* Bar width+1px */
        padding: 0 15px 0 0;
      }

      .expandright {
        left: auto;
        right: 49px; /* Button width-1px */
      }

      .expandright:focus {
        padding: 0 0 0 16px;
      }
    }
  }
}

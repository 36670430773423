.post-section-container {
  .post-section-head {
    margin: 2.5rem 0;
    @include displayFlex(center, space-between);
    gap: 1rem;
    flex-wrap: wrap;
    .nav-left-arrow,
    .nav-right-arrow {
      cursor: pointer;
    }
    p {
      color: #8b8b8b;
      font-weight: 600;
    }
  }
  .add-post-section {
    width: 100%;
    height: 20vh;
    background-color: white;
    border-radius: 15px;
    padding: 0 1rem;
    @include displayFlex(center, space-between);
    .add-post-left {
      width: 15%;
      img {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
      }
    }
    .add-post-center {
      width: 60%;
      @include displayFlex(center, flex-start);
      input {
        border: none;
        outline: none;
        font-size: 2rem;
        font-weight: 500;
        color: $shadow-grey;
      }
    }
    .add-post-right {
      width: 25%;
      @include displayFlex(center, space-around);
    }
  }
  .post-details-section {
    width: 100%;
    // height: 50vh;
    border-radius: 15px;
    background-color: white;
    margin: 2.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .post-details-head {
      width: 100%;
      padding: 1% 2%;
      @include displayFlex(center, space-between);
      .post-details-left {
        width: 35%;
        @include displayFlex(center, space-between);
        button {
          @include gradient-btn;
        }
      }
      .post-details-right {
        width: 30%;
        @include displayFlex(center, flex-end);
        button {
          margin-left: 10px;
          @include gradient-btn;
        }
      }
    }
  }
}

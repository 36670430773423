@import './break-points.scss';
@import './colors.scss';
@import './scrollbar.scss';
@import './community/common/variables';
@import './community/common//mixins';

.container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .container1 {
    width: 70%;
    height: 100%;
    flex-grow: 1;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include lg {
      padding-right: 10px;
    }

    .header-container {
      display: grid;
      grid-template-columns: 0.7fr 1.8fr 0.5fr;
      gap: 1rem;
      align-items: center;
      width: 100%;
      height: 7rem;
      position: relative;

      .notification-bell-container {
        border-radius: 50px;
        height: 33px;
        display: flex;
        align-items: center;
        gap: 1rem;

        .notification-bell {
          border-radius: 50px;
          padding: 0.3rem;
          cursor: pointer;
          height: 26px;
        }
        .notification-triangle {
          position: absolute;
          top: 73px;
          left: -0.25rem;
        }

        .search-bar {
          display: flex;
          align-items: center;
          width: -webkit-fill-available;
          max-width: 250px;
          min-width: 150px;
          height: 1.9419rem;
          background-color: #fff;
          border-radius: 50px;
          padding-left: 10px;

          img {
            cursor: pointer;
            height: 15px;
          }

          input {
            height: 100%;
            width: 75%;
            border: none;
            outline: none;
            padding-left: 5px;
            &:placeholder {
              color: black;
            }
          }

          @include lg {
            width: 9rem;
            height: 2rem;
          }
        }
      }

      .icons-container {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;

        .plus-icon {
          cursor: pointer;
          @include sm {
            height: 20px;
          }
        }
        .chat-icon-container {
          position: relative;
          .chat-icon {
            cursor: pointer;
            @include sm {
              height: 22px;
            }
          }
          .chat-icon-badge {
            right: -1px;
            top: 0;
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #845ed6;
          }
        }
        .notification-triangle {
          position: absolute;
          top: 25px;
          left: 56%;
          z-index: 3;
        }
      }

      ul {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: space-around;

        li {
          list-style: none;
          cursor: pointer;
          color: gray;
          font-weight: 400px;
          font-size: 16px;

          &:hover,
          &.selected {
            color: black;
          }
          &.select {
            color: gray;
            cursor: not-allowed;
          }
        }
      }

      .menu-container {
        display: flex;
        justify-content: flex-end;
        .menu-mui-btn {
          color: black;
          padding: 0;
        }
        @media screen and (max-width: 599px) {
          display: none;
        }
      }

      .creato-wallet-container {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        @include md {
          width: 150px;
        }
      }

      .creato-wallet-container-mb {
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 20px;
        background: white;
        padding: 0.5rem 1.5rem 0.5rem 0.75rem;
        justify-content: flex-end;
        @include sm {
          padding: 3px 8px;
          gap: 0.4rem;
        }
        .creato-wallet-mb-dollor-icon {
          height: 30px;
          width: 30px;
          @include sm {
            height: 18px;
            width: 18px;
          }
        }
        .creato-wallet-mb-text {
          color: #1e152f;
          font-weight: bold;
          font-size: 1.35rem;
          @include sm {
            font-size: 0.9rem;
          }
        }
      }

      @media screen and (max-width: 599px) {
        .creato-wallet-container,
        ul,
        .notification-bell-container {
          display: none;
        }

        .container2-scroll,
        .container2-scroll-hide {
          width: 0;
        }
      }

      @media screen and (min-width: 600px) {
        .creato-wallet-container-mb,
        #app-title,
        .icons-container {
          display: none;
        }
      }

      @include md {
        #app-title {
          font-size: 1.35rem;
        }
      }
    }

    .header2-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5.4287rem;
      width: 100%;
      position: relative;

      .search-image-container {
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        p {
          font-weight: 600;
          font-size: 0.625rem;
        }

        .image-container {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          border: 1px solid skyblue;
          overflow: hidden;
          img {
            margin-top: -0.3875rem;
            margin-left: -0.6375rem;
            height: 65px;
          }
          @include lg {
            width: 2rem;
            height: 2rem;
            img {
              margin-left: -10px;
              margin-top: -6px;
            }
          }
        }
      }
    }

    .home-content-area {
      width: 100%;
      height: calc(100% - 7rem);
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .left-container {
        height: 100%;
        width: 21%;
        gap: 1rem;

        .Whats-up-container {
          display: flex;
          height: 5.4375rem;
          gap: 15px;

          .Whats-up-container-left {
            height: 100%;
            min-width: 86px;
            border-radius: 20px;
            overflow: hidden;
            cursor: pointer;
            img {
              height: 100%;
              width: 100%;
            }
            @include lg {
              min-width: 60px;
              height: 80%;
            }
          }

          .Whats-up-container-right {
            height: 100%;
            line-height: 1.7;
            display: flex;
            flex-direction: column;

            justify-content: center;
            p {
              font-size: 0.9375rem;
              @include lg {
                font-size: 13px;
              }
              @include md {
                font-size: 10px;
              }
            }
            h3 {
              font-size: 1.5rem;
              @include lg {
                font-size: 16px;
              }
              @include md {
                font-size: 14px;
              }
            }

            @include lg {
              p {
                font-size: 0.875rem;
              }
              h1 {
                font-size: 2.25rem;
              }
            }
          }
        }

        .left-container-events {
          height: calc(100% - 5.4375rem);
          overflow-y: auto;
          width: 100%;
          align-items: center;
          padding-bottom: 0.625rem;

          &::-webkit-scrollbar {
            display: none;
          }

          @include lg {
            p {
              font-size: 14px;
            }
          }

          #event-img {
            width: inherit;
            background-repeat: no-repeat;
          }

          .left-side-image-container {
            width: 100%;
            border-radius: 30px;
            overflow: hidden;
          }
        }
      }

      .right-container-scroll {
        width: 71%;
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .right-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 40px;
          padding-bottom: 0.625rem;
          @include lg {
            gap: 30px;
          }

          .status-container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            // height: 217px;
            height: 13.5rem;
            background-color: #fff;
            border-radius: 50px;
            @include lg {
              height: 11rem;
            }
            @include md {
              height: 9rem;
              border-radius: 35px;
            }

            .status-container-top {
              display: flex;
              width: 100%;
              height: 6.25rem;
              @include lg {
                height: 5rem;
              }
              @include md {
                height: 4rem;
              }

              .status-container-top1 {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                width: 100px;
                height: 100%;
                @include md {
                  justify-content: center;
                  width: 80px;
                }

                img {
                  height: 50px;
                  width: 50px;
                  border-radius: 8px;
                  cursor: pointer;
                }
              }

              .status-container-top2 {
                height: 100%;
                display: flex;
                flex-direction: column;
                margin-left: 15px;

                .color-squares {
                  width: 50px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: auto;
                  height: 50px;

                  div {
                    height: 13px;
                    width: 13px;
                    border-radius: 3px;
                    cursor: pointer;
                  }
                }

                .square1 {
                  background-color: #7c7ef7;
                }
                .square2 {
                  background-color: #f8c56c;
                }
                .square3 {
                  background-color: #fe98bf;
                }
                .square4 {
                  background-color: #52caed;
                }
                .square5 {
                  background-color: #69bdf9;
                }
                .square6 {
                  background-color: #c1c1c1;
                  color: #5e5e5e;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .status-bar {
                  display: flex;
                  gap: 10px;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 3.125rem;

                  input {
                    color: #858585;
                    border: none;
                    outline: none;
                  }
                  .everyone-select {
                    border-radius: 0.5rem;
                    @include md {
                      font-size: 10px;
                      height: 16px;
                      width: 75px;
                    }
                  }

                  select {
                    width: 5.625rem;
                    height: 1.375rem;
                    background-color: #eeedff;
                    border-radius: 0.25rem;
                    border: none;
                    outline: none;
                  }
                }
              }
            }
            .status-container-bottom {
              position: relative;
              width: 100%;
              height: 6.25rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 1.5625rem 0 2.5rem;
              @include lg {
                height: 5rem;
              }
              @include md {
                padding: 0 15px 0 15px;
              }

              .status-container-bottom-left,
              .status-container-bottom-right {
                display: flex;
                align-items: center;
                gap: 0.625rem;

                @include sm {
                  gap: 5px;
                }

                .plus18 {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #52caed;
                  color: white;
                  height: 1.625rem;
                  width: 1.625rem;
                  border-radius: 3.125rem;
                  font-size: 0.6875rem;
                }
                p {
                  font-size: 12px;
                }
                img {
                  height: 1.25rem;
                }
                .article {
                  // height: 25px;
                  padding: 0.4375rem 0.3125rem;
                  border-radius: 3.125rem;
                  background-color: #eeedff;
                  font-size: 0.75rem;
                  display: flex;
                  align-items: center;
                  border: none;
                  outline: none;
                  @include lg {
                    font-size: 10px;
                    padding: 0.3125rem 0.25rem;
                    width: 3.625rem;
                  }
                  @include md {
                    // font-size: 10px;
                    padding: 4px 3px;
                    // width: 49px;
                  }
                }

                .choose-community {
                  border: none;
                  outline: none;
                  background-color: #eeedff;
                  padding: 0.4375rem 0.3125rem;
                  border-radius: 3.125rem;
                  p {
                    font-size: 0.75rem;
                    @include lg {
                      font-size: 0.625rem;
                    }
                  }
                  @include lg {
                    font-size: 10px;
                    padding: 5px 4px;
                    width: 120px;
                  }
                  @include md {
                    padding: 4px 3px;
                  }
                }
                .dollor-image {
                  @include lg {
                    height: 1rem;
                  }
                  @include md {
                    height: 0.8rem;
                  }
                }

                // checkbox
                .switch {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .react-switch-checkbox {
                  height: 0;
                  width: 0;
                  visibility: hidden;
                }

                .react-switch-label {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  width: 60px;
                  height: 24px;
                  background: grey;
                  border-radius: 100px;
                  position: relative;
                  transition: background-color 0.2s;
                  @include lg {
                    width: 35px;
                    height: 17px;
                  }
                  @include md {
                    width: 30px;
                    height: 13px;
                  }
                }

                // .react-switch-label:checked{}

                .react-switch-label .react-switch-button {
                  content: '';
                  position: absolute;
                  top: 0.125rem;
                  left: 0.125rem;
                  width: 1.1875rem;
                  height: 1.1875rem;
                  border-radius: 2.8125rem;
                  transition: 0.2s;
                  background: #fff;
                  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
                  @include lg {
                    width: 13.5px;
                    height: 13.5px;
                  }
                  @include md {
                    width: 9px;
                    height: 9px;
                  }
                }

                .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
                  left: calc(100% - 2px);
                  transform: translateX(-100%);
                }

                .react-switch-checkbox:checked ~ .react-switch-label {
                  background-color: #7c7ef7;
                }

                .react-switch-label:active .react-switch-button {
                  width: 1.625rem;

                  @include lg {
                    width: 1.375rem;
                  }
                }

                .post {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 7.8125rem;
                  height: 2.5rem;
                  background-color: #7c7ef7;
                  font-size: 1.25rem;
                  color: white;
                  border-radius: 0.9375rem;
                  cursor: pointer;
                  @include lg {
                    font-size: 1rem;
                    width: 4rem;
                    height: 2rem;
                  }
                }
              }
            }
            .diamond-icon-container {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 0;
              height: 3.75rem;
              width: 4.375rem;
              background: linear-gradient(0deg, #7b7ef6, #7b7ef6), linear-gradient(0deg, #7c7ef7, #7c7ef7);
              border-radius: 0 50px 0 45px;

              img {
                height: 1.125rem;
              }
              @include lg {
                height: 3.5rem;
                width: 4rem;
                img {
                  height: 1rem;
                }
              }
              @include md {
                border-radius: 0 30px 0 30px;
                height: 3rem;
                width: 3rem;
                img {
                  height: 0.8rem;
                }
              }
            }
          }

          .pages-selector {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            width: 100%;
            height: auto;
            ul {
              display: flex;
              justify-content: center;
              align-items: center;
              li {
                list-style: none;
                font-size: 18px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 25px;
                color: #8d8b8b;
              }
              .page-selector-li {
                position: relative;
                padding: 0 25px;
                list-style: none;
                font-size: 18px;
                color: #8d8b8b;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @include lg {
                  font-size: 16px;
                  padding: 0 18px;
                }

                .under {
                  position: absolute;
                  top: 25px;
                  margin-top: 5px;
                  width: 7px;
                  height: 7px;
                  border-radius: 50px;

                  &.selected {
                    background: #7c7ef7;
                  }
                }
                &:hover,
                &.selected {
                  color: black;
                  .under {
                    background: #7c7ef7;
                  }
                }
              }
            }
          }

          @media screen and (max-width: 599px) {
            .pages-selector ul {
              :nth-child(2),
              :nth-child(4) {
                display: none;
              }
            }
            .pages-selector #settingIcon {
              display: none;
            }
          }
        }
      }

      @media screen and (max-width: 599px) {
        .right-container-scroll {
          width: 100%;
          position: relative;
          padding-bottom: 5.3125rem;

          .right-container .status-container {
            // display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 599px) {
    .container {
      width: 100% !important;
    }
    .container1 {
      padding-right: 0;
    }
  }

  .container2-scroll-hide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 5%;
    height: 100vh;
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
    @include lg {
      display: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .grid-option {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        cursor: pointer;
        margin: 20px 30px;
      }
    }
  }
  .container2-scroll {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 27%;
    height: 100vh;
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
    &::-webkit-scrollbar {
      display: none;
    }
    @include xl {
      width: 10%;
    }
    @include lg {
      display: none;
    }

    .grid-option {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        cursor: pointer;
        margin: 20px 30px;
      }
    }

    // .container2-hide {
    //   display: none !important;
    // }

    .container2 {
      width: 100%;
      height: auto;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 28px;
      padding-bottom: 2rem;
      line-height: 1;
      @include xl {
        display: none;
      }

      .suggestion-options {
        width: 70%;
        cursor: pointer;

        ul {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          #disabled,
          #enabled {
            list-style: none;
            font-size: 1em;
            padding: 0.25rem 0.45rem;
            margin: 0 3.5px;
            font-weight: 600;
            color: rgb(167, 173, 177);
            border-radius: 50px;
          }

          #enabled {
            &:hover,
            &.selected {
              color: rgb(255, 255, 255);
              background-color: #6c6ff5;
            }
          }

          #disabled {
            cursor: not-allowed;
          }
        }
      }

      .suggestion-container {
        max-width: 19rem;
        width: 85%;
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: white;

        #artist {
          width: 80%;
          height: 11vw;
          cursor: pointer;
        }

        #detail {
          cursor: pointer;
          font-size: 0.875rem;
          color: #1e152f;
          font-weight: bold;
        }

        .details-container {
          display: flex;
          justify-content: center;
          gap: 1rem;
          align-items: center;
          span {
            cursor: pointer;
            font-size: 0.875rem;
            color: #1e152f;
            font-weight: bold;
          }
        }

        // .varunKumar-image-container-right {
        //   // width: 16%;
        //   display: flex;
        //   flex-direction: column;
        //   align-items: flex-end;
        //   gap: 20px;
        //   div {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     height: 2.125rem;
        //     width: 2.3125rem;
        //     border-radius: 10px;
        //     background-color: white;
        //   }
        // }
      }

      .invite-friends {
        width: 83%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 14px;
        }
        ul {
          display: flex;
          width: 40%;
          justify-content: space-between;
          li {
            list-style: none;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }
      }

      .groups {
        width: 90%;
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .category-body {
          padding: 1% 0;
          ul {
            list-style-type: none;
            display: flex;
            align-items: center;
            gap: 1rem;
            overflow-x: auto;
            &::-webkit-scrollbar {
              display: none;
            }
            padding: 0.5rem;

            li {
              width: 10vw;
              aspect-ratio: 1/1;
              box-shadow: 0px 4px 12px rgba(36, 33, 50, 0.12);
              border-radius: 20px;
              height: 170px;
              cursor: pointer;
              .card-head {
                width: 100%;
                height: 25%;
                position: relative;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                .card-logo {
                  position: absolute;
                  width: 40px;
                  aspect-ratio: 1/1;
                  border-radius: 50%;
                  border: 2px solid white;
                  bottom: -10%;
                  right: 0;
                  z-index: 2;
                  transform: translate(-50%, 50%);
                  @include displayFlex(center, center);
                  h1 {
                    font-size: 1.5rem;
                    color: white;
                  }
                }
              }
              .card-body {
                height: 76%;
                background-color: white;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                clip-path: polygon(0 14%, 100% 1%, 100% 100%, 0% 100%);

                @include displayFlex(flex-end, center);
                .card-body-left {
                  flex: 1.5;
                  height: 85%;
                  padding: 0.5rem;
                  @include displayFlex(flex-start, space-between, column);
                  .card-left-head {
                    h1 {
                      font-size: calc(8px + 0.6vw);
                    }
                    p {
                      font-size: calc(8px + 0.5vw);
                      color: #888888;
                    }
                  }
                  .card-left-foot {
                    p {
                      font-size: calc(8px + 0.2vw);
                    }
                    p:nth-child(1) {
                      white-space: nowrap;
                    }
                    p:nth-child(2) {
                      margin-top: 3px;
                      color: #888888;
                    }
                  }
                }
                .card-body-right {
                  flex: 1;
                  height: 85%;
                  padding: 0.5rem 0;
                  @include displayFlex(flex-start, space-between, column);
                  .card-right-foot {
                    p {
                      color: #6fcd96;
                      font-size: calc(8px + 0.2vw);
                    }
                  }
                }
              }
            }
          }
        }

        .group-container {
          // display: flex;
          // align-items: center;
          height: 130px;
          width: 100%;
          overflow-x: scroll;
          overflow-y: hidden;
          white-space: nowrap;
          &::-webkit-scrollbar {
            display: none;
          }

          div {
            display: inline-block;
            height: 130px;
            width: 150px;
            border-radius: 20px;
            margin: 0 10px;
          }
        }
      }
      .hash-tags {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        max-height: 350px;
        h4 {
          align-self: flex-start;
          margin-bottom: 0.5rem;
        }

        .hash-tag-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          gap: 0.5rem;
          overflow-y: auto;
          @extend .scroll-bar-4;
          justify-content: center;

          #tag-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            gap: 2px;
            padding: 0.5rem 0.7rem;
            font-size: 0.9rem;
            font-weight: 600;
            img {
              margin-left: 10px;
            }
            span {
              font-weight: 500;
            }
          }
        }
      }

      .calendar {
        width: 80%;

        .react-calendar {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 599px) {
    .container2-scroll,
    .container2-scroll-hide,
    .left-container {
      display: none;
    }
  }
}

.post-card {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 3.125rem;
  gap: 0.625rem;
  padding: 1.875rem;
  color: var(--theme-font-color);
  @include lg {
    padding: 15px;
    border-radius: 25px;
  }

  .post-card-header {
    // height: 4.375rem;
    flex-wrap: wrap;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @extend .scroll-bar-4;

    .user-details {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: center;
      @include lg {
        justify-content: space-between;
        width: 100%;
      }
      @media (max-width: 400px) {
        justify-content: flex-start;
      }

      .user-image {
        height: 4rem;
        cursor: pointer;
        border-radius: 50%;
        width: 4rem;
        @include xl {
          height: 3.5rem;
          width: 3.5rem;
        }
        @include lg {
          height: 3rem;
          width: 3rem;
        }
        @include sm {
          height: 2.2rem;
          width: 2.2rem;
        }
      }
      .user-name {
        font-size: 1.25rem;
        font-weight: bold;
        cursor: pointer;
        @include xl {
          font-size: 1rem;
        }
        @include lg {
          font-size: 0.85rem;
        }
      }

      .chip {
        border-radius: 0.5rem;
        color: white;
        height: unset !important;
        cursor: pointer;
        font-size: 0.75rem;
        padding: 0.1rem 0;
        @include xl {
          font-size: 0.7rem;
        }
        @include lg {
          font-size: 0.6rem;
        }
        @include sm {
          font-size: 0.5rem;
        }
      }
      .user-location-icon {
        height: 1rem;
        color: #ababb1;
        @include xl {
          font-size: 0.75rem;
        }
        @include lg {
          font-size: 0.7rem;
        }
        @include sm {
          font-size: 0.5;
        }
      }

      .user-location {
        @include xl {
          font-size: 0.75rem;
        }
      }
      .menu-time-container {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding-left: 0.5rem;
        align-items: flex-start;
        @include xl {
          padding-left: 0;
          gap: 0.6rem;
        }
        @include lg {
          align-items: flex-end;
          margin-left: auto;
        }
        @media (max-width: 400px) {
          display: none;
        }

        .menu-wrap {
          display: flex;
          align-items: center;
          padding: 0.3125rem;
          color: white;
          background: linear-gradient(102.89deg, #7c7ef7 17%, #52caed 152.44%);
          border-radius: 0.25rem;
          gap: 0.5rem;
          // align-self: flex-start;

          @include xl {
            padding: 0.1563rem;
            gap: 0.25rem;
          }
          @include lg {
            gap: 0.15rem;
          }

          .menu-icon {
            @include xl {
              font-size: 0.75rem;
            }
          }
          .menu-icon-text {
            @include xl {
              font-size: 0.75rem;
            }
            @include lg {
              line-height: 1;
            }
          }
        }
      }
    }
    .user-time-left-icon {
      height: 1rem;
      @include xl {
        height: 0.75rem;
      }
      @include lg {
        height: 0.65rem;
        width: 0.65rem;
        padding-right: 0.1rem;
      }
      @include sm {
        height: 0.55rem;
        width: 0.55rem;
      }
    }

    .user-time-left {
      line-height: 1;
      @include xl {
        font-size: 0.75rem;
      }
      @include lg {
        height: 0.65rem;
      }
      @include sm {
        font-size: 0.6rem;
        line-height: 1.2;
      }
    }

    .icon-btn {
      color: var(--theme-font-color);
      padding: 0;
      svg {
        &:hover {
          background-color: var(--theme-hover);
        }
      }
      @include lg {
        display: none;
      }
    }
    .workspace-icon {
      @include xl {
        font-size: 1rem;
      }
    }
  }

  .post-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 54px;
    width: 100%;

    .icon-grp {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .fav-icon-button {
        color: #ff93bc;
        background: #fceef6;
        padding: 1rem;
        border-radius: 1.25rem;
        @include lg {
          padding: 0.75rem;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 14px;
        }
        @include sm {
          padding: 0.5rem;
          height: 1.6rem;
          width: 1.6rem;
          border-radius: 8px;
        }
        .fav-icon {
          @include lg {
            font-size: 1.3rem;
          }
          @include sm {
            font-size: 0.8rem;
          }
        }
      }

      .chat-icon-button {
        color: #68a6fe;
        background: #ecf3ff;
        padding: 1rem;
        border-radius: 1.25rem;
        @include lg {
          padding: 0.75rem;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 14px;
        }
        @include sm {
          padding: 0.5rem;
          height: 1.6rem;
          width: 1.6rem;
          border-radius: 8px;
        }
        .chat-icon {
          @include lg {
            font-size: 1.3rem;
          }
          @include sm {
            font-size: 0.8rem;
          }
        }
      }

      .forward-icon-button {
        color: #7b7ef6;
        background: #f4f4ff;
        padding: 0.75rem;
        border-radius: 1.25rem;
        svg {
          font-size: 2rem;
          transform: rotateY(180deg);
        }
        @include lg {
          padding: 0.75rem;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 14px;
        }
        @include sm {
          padding: 0.5rem;
          height: 1.6rem;
          width: 1.6rem;
          border-radius: 8px;
        }
        .forward-icon {
          @include lg {
            font-size: 1.5rem;
          }
          @include sm {
            font-size: 1rem;
          }
        }
      }
    }

    .btn-grp {
      display: flex;
      gap: 1rem;
      align-items: center;
      @include sm {
        gap: 0.55rem;
      }
      .reaction-count {
        display: flex;
        background: linear-gradient(to bottom right, #c7c8ff, #7c7ef7);
        border-radius: 0.5rem;
        align-items: center;
        cursor: pointer;
        padding: 0 0.5rem;
        position: relative;
        @include sm {
          border-radius: 0.25rem;
          padding: 0 0.2rem;
          height: 20px;
        }
        .star-icon {
          color: #ffc123;
          padding-right: 0.25rem;
          font-size: 1.75rem;
          @include lg {
            font-size: 18px;
          }
          @include sm {
            font-size: 12px;
          }
        }
        .count {
          color: #fff;
          padding: 0.25rem;
          border-left: 1px solid #fff;
          @include lg {
            font-size: 14px;
          }
          @include sm {
            font-size: 10px;
          }
        }

        .emoji-reaction {
          position: relative;
          margin-right: 4px;
          z-index: 3;
          p {
            font-size: 1.25rem;
          }
        }
      }

      .stake-btn {
        text-transform: none;
        color: #fff;
        background: #7c7ef7;
        font-weight: bold;
        border-radius: 0.5rem;
        padding: 0.3rem 1rem;
        &:hover {
          background: #6c6ff5;
        }
      }

      .money-icon-container {
        background: #ffc123;
        border-radius: 50%;
        color: #fff;

        @include lg {
          padding: 4px;
        }
        .money-icon {
          @include lg {
            font-size: 1rem;
          }
          @include sm {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

@import '../scrollbar.scss';
@import '../break-points.scss';

.chat-container {
  margin: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(123.15deg, #f6ebf8 10.02%, #edeeff 87.43%);
  border-radius: 25px;
  border: 2px solid #9065ec;

  // @media screen and (max-width: 599px) {
  //     width:160px !important;
  //     height:35px !important;
  //     }
  .chat-heading {
    width: 100%;
    // height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    // @media screen and (max-width: 599px) {
    //   padding: 0.5rem;
    // }
    svg {
      color: #9065ec;
    }

    .chat-heading-icon {
      // @media screen and (max-width: 599px) {
      //   height:1rem !important;
      //   width: 1rem !important;
      // }
    }
    p {
      display: flex;
      align-items: center;
      font-weight: 600;
      cursor: pointer;
      @media screen and (max-width: 599px) {
        font-size: 14px;
      }
    }
    .chat-icons {
      display: flex;
      align-items: center;
      gap: 0 8px;
      cursor: pointer;
    }
  }
  .chat-search-input {
    width: 100%;
    padding: 0 1rem;

    .chat-input-with-icon {
      height: 31px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: white;
      border-radius: 14px;
      padding: 5px;
      img {
        margin-left: 0.5rem;
      }
      input {
        width: 93%;
        height: 90%;
        outline: none;
        border: none;
        border-radius: 14px;
        padding-left: 10px;
      }
    }
  }
  .chat-content {
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 31.5rem);
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;

    @extend .scroll-bar-4;

    .chat-border {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: space-between;

      .chat-message {
        margin-left: 10px;
        line-height: 1.5;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          cursor: pointer;

          img {
            height: 100%;
          }
        }

        h3 {
          font-size: 16px;
          cursor: pointer;
        }
        p {
          font-size: 13px;
        }
      }

      #noti-count {
        text-align: center;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 16px;
        color: #ffffff;
        width: 1.6875rem;
        height: 1.625rem;
        border-radius: 50%;
        background: #7c7ef7;
        padding: 0.3rem;
      }
      .chat-seen {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: 12px;
      }
    }
    .no-user {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@import './scrollbar.scss';
@import './break-points.scss';

.notification-modal-container {
  height: auto;
  max-height: 265px;
  width: 23.4375rem;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  z-index: 2;
  top: 5.625rem;
  left: -1.875rem;

  @media screen and (max-width: 599px) {
    top: 2.6rem;
    left: 0;
  }
  @include sm {
    width: 20rem;
    max-width: calc(100vw - 2rem);
  }

  &.see-more {
    max-height: none !important;
  }

  .notification-heading {
    padding: 15px 20px 15px 30px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-between;
    h4 {
      font-size: 15px;
      @media screen and (max-width: 599px) {
        font-size: 12px;
      }
    }
    p {
      cursor: pointer;
      font-size: 13px;
      color: #52caed;
      @media screen and (max-width: 599px) {
        font-size: 10px;
      }
    }
  }

  .notification-messages {
    padding: 0 20px 0 30px;
    width: 100%;
    max-height: 390px;
    overflow-y: auto;
    @extend .scroll-bar-4;

    .notification-message-border {
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-top: 1.3px solid #f0f3f7;
      padding: 15px 0;

      .notification-image-container {
        position: relative;
        height: 31px;
        width: 31px;
        margin-right: 10px;
        border-radius: 50px;
        overflow: hidden;
        img {
          position: absolute;
          top: -4px;
          left: -6px;
          height: 44px;
        }
      }
      .notification-message-area {
        width: calc(100% - 42px);
        line-height: 1.3;
        .notification-name {
          display: flex;
          justify-content: space-between;
          h4 {
            font-size: 14px;
            @media screen and (max-width: 599px) {
              font-size: 12px;
            }
          }
          p {
            font-size: 10px;
            color: #c5c5c5;
            @media screen and (max-width: 599px) {
              font-size: 8px;
            }
          }
        }
        p {
          font-size: 10px;
          color: #777777;
          @media screen and (max-width: 599px) {
            font-size: 8px;
          }
        }
      }
    }
  }
  .notification-see-all {
    padding: 10px 20px 10px 30px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 13px;
    color: #52caed;
    cursor: pointer;
    @media screen and (max-width: 599px) {
      font-size: 11px;
    }
  }
}

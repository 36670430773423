@mixin scheduleButton{
  background-color:$theme-blue;
  color: white;
  border: none;
  font-size: 0.8rem;
  padding:3px 5px;
  width: 150px;
  height: 40px;
  border-radius: 0.5rem;
  box-shadow: 0 5px 10px $shadow-grey;
  cursor: pointer;
  @include md{
    width: 100px;
    height: 30px;
    margin-top: 5%;
  }
}

@mixin bgColor($color) {
    background-color: $color;
}

@mixin displayFlex($value){
  display: flex;
  align-items: center;
  justify-content: $value;
}
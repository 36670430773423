.community-topics-container {
  @import './head/createCommunityHead';
  @include sm{
    height: 70vh;
  }
  .com-top-body {
    height: 75%;
    margin: 3% 0;
    .input-container {
      background: #f9f9f9;
      border: 1.5px solid #f2f2f2;
      border-radius: 18px;
      @include displayFlex(flex-start, center, column);
      margin: $overall-margin;
      padding: 2%;
      @include sm{
        padding: 3%;
      }
      label {
        color: #9c9c9c;
      }
      input {
        margin: 1% 0;
        border: none;
        outline: none;
        background-color: transparent;
        &::placeholder {
          color: $theme-blue-violet;
        }
      }
    }
    .add-topic-btn {
      margin: $overall-margin;
      color: #9c9c9c;
      cursor: pointer;
      &:hover {
        color: $theme-blue-violet;
      }
    }
    .topic-list-container {
      margin: 1% 0;
      ul {
        max-height: 30vh;
        overflow-y: scroll;
        list-style-type: none;
        padding: $overall-padding;
        li {
          position: relative;
          margin: 2% 0;
          background: #f9f9f9;
          border: 1.5px solid #f2f2f2;
          border-radius: 18px;
          @include displayFlex(flex-start, center, column);
          padding: 2%;
          p:nth-child(1) {
            color: #9c9c9c;
          }
          p:nth-child(2) {
            cursor: pointer;
          }
          .edit-topic {
            border:1px solid #dadada;
            display: none;
            outline: none;
            padding: 1%;
            background-color: transparent;
          }
          .inactive{
              display: none;
          }
          .active{
              display: block;
          }
          img {
            position: absolute;
            top: 50%;
            right: 5%;
            transform: translate(0, -50%);
            cursor: pointer;
          }
        }
      }
    }
  }
}

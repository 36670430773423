//sections
@import './header';
@import './community';
@import './post';

.hw-main-section {
  width: calc(25vw + 8.5rem);
  flex-grow: 1;
  overflow-y: scroll;
  padding: 2rem 2rem 0 2rem;
  &::-webkit-scrollbar {
    display: none;
  }
  @include lg{
    padding: 0;
  }
}
